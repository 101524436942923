import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { FlexContainer } from "../../components/FlexContainer/FlexContainer";
import { Button } from "../../components/Button/Button";
import { Header } from "../../components/Header/Header";
import { PageContainer } from "../../components/PageContainer/PageContainer";
import { PageHeader } from "../../components/PageHeader/PageHeader";
import "./ExtendedBankIdInfo.css";
import useStore, { DemoStore } from "../../hooks/useStore";
import { BankId } from "../../utils/enums";
export const ExtendedBankIdInfo = ({ onNextClick }) => {
  const history = useHistory();
  const [isChecked, setIsChecked] = useState(false);
  const bankName = useStore((store: DemoStore) => store.bankName);
  return (
    <>
      <Header onBackClick={() => history.push("/scope")} />
      <PageContainer>
        <FlexContainer justifyContent="center" alignItems="center">
          <img src={`${BankId.SWEDSESS}.png`} width="80px" height="80px" />
        </FlexContainer>
        <PageHeader text="Information" />
        <p className="extendedbankidpara">
          To initiate payments with {bankName}, you first need to{" "}
          <a
            className="extendedbankidpara"
            href="https://www.swedbank.se/share/layer-content/privat/digitala-tjanster/mobilt-bankid/mobilt-bankid-med-utokad-anvandning/aktivera-mobilt-bankid-med-utokad-anvandning.html"
            target="_blank"
          >
            enable mobile BankID for extended usage.
          </a>
        </p>
        <div className="CheckboxContainer">
          <input
            type="checkbox"
            className="Checkbox"
            onChange={() => setIsChecked((prev) => !prev)}
            checked={isChecked}
          />
          <label
            className="Label"
            onClick={() => setIsChecked((prev) => !prev)}
          >
            I have enabled extended usage of mobile BankID
          </label>
        </div>
        <Button text="Next" disabled={!isChecked} onClick={onNextClick} />
      </PageContainer>
    </>
  );
};
