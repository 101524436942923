import React from "react";
import { FlexContainer } from "../../components/FlexContainer/FlexContainer";
import { PageContainer } from "../../components/PageContainer/PageContainer";
import useStore, { DemoStore } from "../../hooks/useStore";
import { Header } from "../../components/Header/Header";

import "./InvoiceSuccessful.css";
import { config, useSpring, animated } from "react-spring";
import { dateAsText } from "../../utils/functions";
export const InvoiceSuccessful = () => {
  const { invoiceAccount, invoice, invoiceDate } = useStore(
    (state: DemoStore) => state
  );
  const checkmarkStyle = useSpring({
    from: {
      transform: "scale(0)",
    },
    to: {
      transform: "scale(1)",
    },
    config: { ...config.wobbly, mass: 1.2 },
    delay: 200,
  });

  const getAmountText = (invoice) =>
    isRecurring(invoice)
      ? `SEK ${invoice.amount}.00 / month`
      : `SEK ${invoice.amount}.00`;

  const isRecurring = (invoice) =>
    invoice.paymentType.id === "periodic-payments";

  return (
    <>
      <Header isInvoice showCompanyName />
      <PageContainer className="PageContainerInvoiceSuccessful">
        <div></div>
        <FlexContainer flexDirection="column" alignItems="center">
          <animated.img
            className="InvoiceSuccessfulCheckmark"
            src="checkbox_circle.png"
            height="70px"
            alt="echecklasd"
            style={checkmarkStyle}
          />
          <span className="InvoiceSuccessfulHeader">
            Din betalning är skickad
          </span>
          <FlexContainer
            justifyContent="space-between"
            className="namevaluecontainer"
            fullWidth
          >
            <span className="InvoiceSuccessfulName">Amount</span>
            <span className="InvoiceSuccessfulValue">
              {getAmountText(invoice)}
            </span>
          </FlexContainer>
          <FlexContainer
            fullWidth
            justifyContent="space-between"
            className="namevaluecontainer"
          >
            <span className="InvoiceSuccessfulName">Receiver</span>
            <span className="InvoiceSuccessfulValue">Företaget AB</span>
          </FlexContainer>
          <FlexContainer
            fullWidth
            justifyContent="space-between"
            className="namevaluecontainer"
          >
            <span className="InvoiceSuccessfulName">
              {isRecurring(invoice) ? "First payment" : "Payment date"}
            </span>
            <span className="InvoiceSuccessfulValue">
              {dateAsText(
                isRecurring(invoice) ? invoice.recurringStartDate : invoiceDate
              )}
            </span>
          </FlexContainer>
          <FlexContainer
            fullWidth
            justifyContent="space-between"
            className="namevaluecontainer"
          >
            <span className="InvoiceSuccessfulName">Från konto</span>
            <span className="InvoiceSuccessfulValue">
              {invoiceAccount.name}
            </span>
          </FlexContainer>
        </FlexContainer>
      </PageContainer>
    </>
  );
};
