import { FlexContainer } from "../../FlexContainer/FlexContainer";
import React from "react";
import "./StatusCode.css";
export const StatusCode = ({ code }) => (
  <FlexContainer className="StatusCode">
    <span className="Color-White">[</span>
    <span className="Color-Yellow">{code}</span>
    <span className="Color-White">]</span>
  </FlexContainer>
);
