import React, { ReactNode } from "react";
import { FlexContainer } from "../../../../components/FlexContainer/FlexContainer";
import "./Field.css";

interface Props {
  name: string;
  value?: string;
  icon?: string;
  component?: ReactNode;
}

export const Field: React.FC<Props> = ({ name, value, icon, component }) => {
  return (
    <FlexContainer className="Field" flexDirection="column">
      <span className="FieldName">{name}</span>
      {!component ? (
        icon ? (
          <FlexContainer>
            <span className="FieldValue">{value}</span>
            <img
              src={icon}
              alt={icon}
              height="20px"
              style={{ marginLeft: 10 }}
            />
          </FlexContainer>
        ) : (
          <span className="FieldValue">{value}</span>
        )
      ) : (
        component
      )}
    </FlexContainer>
  );
};
