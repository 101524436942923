import "./Loader.css";
import spinner from "../../assets/spinner.gif";

export const Spinner = ({ text }) => {
  return (
    <div className="TheLoader">
      <img src={spinner} height={100} width={100} alt="loader" />
      <div className="TheLoaderText">{text}</div>
    </div>
  );
};
