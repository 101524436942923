import React from "react";
import { Endpoint } from "./Endpoint";
import { StatusCode } from "./StatusCode";
import { Timestamp } from "./Timestamp";
import "./ApiCall.css";
import { config, animated, useSpring } from "react-spring";

export const ApiCall = ({ endpoint, statusCode, timestamp }) => {
  const styles = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: config.gentle,
  });
  return (
    <animated.div className="ApiCall" style={styles}>
      <Timestamp time={timestamp} />
      <StatusCode code={statusCode} />
      <Endpoint endpoint={endpoint} />
    </animated.div>
  );
};
