import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { config, animated, useTransition } from "react-spring";
import { getInvoice } from "../../api/endpoints";
import { FlexContainer } from "../../components/FlexContainer/FlexContainer";
import { Header } from "../../components/Header/Header";
import { PageContainer } from "../../components/PageContainer/PageContainer";
import { PageHeader } from "../../components/PageHeader/PageHeader";
import { InvoiceStatusEnum } from "../../utils/enums";
import "./InvoiceStatus.css";

export const InvoiceStatus = () => {
  const params = new URLSearchParams(window.location.search);
  let invoiceID = params.get("id");
  const [events, setEvents] = useState([]);

  const { data: invoice } = useQuery(
    "getInvoice",
    () => getInvoice(invoiceID),
    {
      refetchInterval: 1000,
      cacheTime: 0,
    }
  );

  useEffect(() => {
    if (
      invoice &&
      !INITIAL_EVENTS.some((event) => event.id === invoice.invoiceStatus) &&
      !events.some((event) => event.id === invoice.invoiceStatus)
    ) {
      setEvents((prev: any[]) => [
        ...prev,
        {
          id: invoice.invoiceStatus,
          text: getInvoiceStatusText(invoice.invoiceStatus),
          immediate: false,
        },
      ]);
    }
  }, [invoice]);

  const transitions = useTransition(events, (item) => JSON.stringify(item), {
    config: config.stiff,
    from: { opacity: 0, transform: "translate3d(0px, -75%, 0px)" },
    enter: { opacity: 1, transform: "translate3d(0%, 0px, 0px)" },
  });

  return (
    <>
      <Header />
      <PageContainer>
        <PageHeader
          text={`Invoice #${invoiceID.split("-")[4].toUpperCase()}`}
        />
        {INITIAL_EVENTS.map((ie) => (
          <div className="InvoiceStatusEvent" key={ie.id}>
            <div
              className={
                ie.id === INITIAL_EVENTS[0].id
                  ? "InvoiceStatusEventSpace Hidden"
                  : "InvoiceStatusEventSpace"
              }
            ></div>
            <FlexContainer alignItems="center">
              <img src="blue_ball.png" alt="ball" className="BlueBall" />
              <span className="InvoiceStatusEventText">{ie.text}</span>
            </FlexContainer>
          </div>
        ))}

        {transitions.map(({ item: event, props, key }) => (
          <animated.div className="InvoiceStatusEvent" style={props} key={key}>
            <div
              className={
                event.id === INITIAL_EVENTS[0].id
                  ? "InvoiceStatusEventSpace Hidden"
                  : "InvoiceStatusEventSpace"
              }
            ></div>
            <FlexContainer alignItems="center">
              <img src="blue_ball.png" alt="ball" className="BlueBall" />
              <span
                className={
                  event.id === InvoiceStatusEnum.PaymentCompleted
                    ? "InvoiceStatusEventTextSuccess"
                    : "InvoiceStatusEventText"
                }
              >
                {event.text}
              </span>
            </FlexContainer>
          </animated.div>
        ))}
      </PageContainer>
    </>
  );
};

const getInvoiceStatusText = (id: string) => {
  switch (id) {
    case InvoiceStatusEnum.PaymentStarted:
      return "Customer initiates payment";
    case InvoiceStatusEnum.PaymentCompleted:
      return "Payment completed!";
    default:
      return "";
  }
};

const INITIAL_EVENTS = [
  {
    id: InvoiceStatusEnum.Created,
    text: "The invoice was created",
    immediate: true,
  },
  {
    id: InvoiceStatusEnum.Received,
    text: "Seen by customer",
    immediate: true,
  },
];
