import { useMutation, useQuery } from "react-query";
import { getAccounts, initiatePayment } from "../../api/endpoints";
import { FlexContainer } from "../../components/FlexContainer/FlexContainer";
import { Button } from "../../components/Button/Button";
import { Spinner } from "../../components/Loader/Loader";
import "./Accounts.css";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { BiLogOut } from "react-icons/bi";
import { Product, ScaMethod } from "../../utils/enums";
import { ErrorMessage } from "../../components/ErrorMessage/ErrorMessage";
import { PageContainer } from "../../components/PageContainer/PageContainer";
import { PageHeader } from "../../components/PageHeader/PageHeader";
import { Header } from "../../components/Header/Header";
import { isAndroid, isMobile } from "../../utils/functions";
import useStore, { DemoStore } from "../../hooks/useStore";
import { getEndpointTimestamp } from "../../utils/functions";
import { AccountDTO } from "../../types/AccountDTO";
import { parseErrorMessage } from "../../utils/reactQuery";

export const Accounts = () => {
  const {
    bankID,
    bankIdInStorage,
    accountsInStorage: accInStorage,
    setAccountsInStorage,
    setIsFromChooseAccount,
    setBankIdInStorage,
    setBankNameInStorage,
    setPnrForAccountInStorage,
    product,
    pnr,
    addApiCall,
    bankName,
    setConfirmPaymentBankIdLink,
    setQrImage,
    setScopeInStorage,
    scope,
    scopeInStorage,
    setScope,
  } = useStore((state: DemoStore) => state);

  const [accounts, setAccounts] = useState<AccountDTO[] | null>(accInStorage);
  const [isInsufficientFunds, setIsInsufficientFunds] = useState(false);
  const selectedAccountInStorage = !accInStorage ? "" : accInStorage[0].id;
  const [selectedAccountId, setSelectedAccountId] = useState<string | null>(
    selectedAccountInStorage
  );

  useEffect(() => setIsFromChooseAccount(true), []);

  const bankId = !accInStorage ? bankID : bankIdInStorage;
  const history = useHistory();

  const {
    data: _accounts,
    isFetching: isLoadingAccounts,
    refetch: fetchAccounts,
    isError: isFetchAccountsError,
  } = useQuery("getAccounts", () => getAccounts(), { enabled: false });

  const {
    mutate: _initiatePayment,
    isLoading: isInitiatingPayment,
    data: initiatePaymentData,
    isSuccess: isInitiatePaymentSuccess,
    error: initiatePaymentError,
    reset: resetmutation,
  } = useMutation((body: any) =>
    initiatePayment({ ...body, isMobile: isMobile() })
  );

  useEffect(() => {
    if (!accounts) {
      fetchAccounts();
    }
  }, [fetchAccounts, accounts]);

  useEffect(() => {
    if (isInitiatePaymentSuccess) {
      addApiCall([
        {
          endpoint: "Create Payment Initiation",
          statusCode: "201",
          timestamp: getEndpointTimestamp(),
        },
        {
          endpoint: "Start Payment Initiation Authorisation Process",
          statusCode: "201",
          timestamp: getEndpointTimestamp(),
        },
        {
          endpoint: "Update PSU Data for Payment Initiation",
          statusCode: "200",
          timestamp: getEndpointTimestamp(),
        },
      ]);
      let { qrCode, scaOAuth, scaMethod, qrImage } = initiatePaymentData;

      setQrImage(qrImage);

      if (scaMethod === ScaMethod.Decoupled) {
        if (isAndroid()) {
          qrCode = qrCode.replace("bankid:///", "https://app.bankid.com/");
        }
        setConfirmPaymentBankIdLink(qrCode);
        resetmutation();
        if (isMobile()) {
          history.push("/paymentopenbankid");
        } else {
          history.push("/confirmpayment");
        }
      } else if (scaMethod === ScaMethod.OAuthRedirect) {
        window.location.href = scaOAuth;
      }
    }
  }, [
    isInitiatePaymentSuccess,
    bankId,
    history,
    initiatePaymentData,
    addApiCall,
    resetmutation,
    setConfirmPaymentBankIdLink,
  ]);

  useEffect(() => {
    if (scopeInStorage) {
      setScope(scopeInStorage);
    }
  }, []);

  useEffect(() => {
    if (_accounts) {
      addApiCall({
        endpoint: "Get Account List",
        timestamp: getEndpointTimestamp(),
        statusCode: "200",
      });
      let accountsWithMoney = _accounts.filter((acc) => +acc.balance > 1);
      if (accountsWithMoney.length > 0) {
        accountsWithMoney =
          accountsWithMoney.length > 2
            ? accountsWithMoney.slice(0, 2)
            : accountsWithMoney;
        setAccounts(accountsWithMoney);

        setSelectedAccountId(accountsWithMoney[0].id);
        setAccountsInStorage(accountsWithMoney);
        setBankIdInStorage(bankId);
        setBankNameInStorage(bankName);
        setScopeInStorage(scope);
        if (!!pnr) {
          setPnrForAccountInStorage(pnr);
        }
      } else {
        setIsInsufficientFunds(true);
      }
    }
  }, [
    _accounts,
    addApiCall,
    bankId,
    bankName,
    pnr,
    setAccountsInStorage,
    setBankIdInStorage,
    setBankNameInStorage,
    setPnrForAccountInStorage,
  ]);

  const completePaymentClick = () => {
    const account = accounts.find(
      (account) => account.id === selectedAccountId
    );

    if (!account) {
      console.error(" no account present");
      return;
    }
    addApiCall({
      endpoint: "Request Payment Initiation token",
      statusCode: "200",
      timestamp: getEndpointTimestamp(),
    });

    _initiatePayment({
      debtorAccount: account.debtorAccount,
    });
  };

  const logout = () => {
    setAccountsInStorage(null);
    setBankIdInStorage(null);
    setBankNameInStorage(null);
    setPnrForAccountInStorage(null);
    history.push("/banks");
  };

  const getLoaderText = () => {
    if (isLoadingAccounts) {
      return "Loading accounts";
    }
    if (isInitiatingPayment) {
      return "Initiating payment";
    }
  };

  if (isInsufficientFunds) {
    return (
      <ErrorMessage
        headerText="Insufficient funds"
        text="It seems like you don't have any account with enough money to make the donation. Try again on the 25th :)"
      />
    );
  }

  if (isFetchAccountsError || initiatePaymentError) {
    return <ErrorMessage />;
  }

  if (!accounts || isLoadingAccounts || isInitiatingPayment) {
    return <Spinner text={getLoaderText()} />;
  }

  if (!!initiatePaymentError) {
    return <ErrorMessage text={parseErrorMessage(initiatePaymentError)} />;
  }

  return (
    <>
      <Header headline="Payment initiation" />
      <PageContainer>
        <FlexContainer justifyContent="space-between" alignItems="center">
          <PageHeader text="Select account to pay from" />
          <BiLogOut
            color="#3f4f5c"
            size="1.4em"
            style={{ marginTop: "-10px", cursor: "pointer" }}
            onClick={logout}
          />
        </FlexContainer>
        <FlexContainer flexDirection="column">
          <div className="AccountList">
            {accounts.map((account) => (
              <Account
                key={account.id}
                account={account}
                selected={selectedAccountId === account.id}
                onClick={() => setSelectedAccountId(account.id)}
              />
            ))}
          </div>
          {product === Product.PaymentInitiation && <Summary />}
          {product === Product.RecurringPayment && <RecurringSummary />}
          <FlexContainer margin="14px 0 0 0" fullWidth justifyContent="center">
            <Button onClick={completePaymentClick} text="Pay now" />
          </FlexContainer>
        </FlexContainer>
      </PageContainer>
    </>
  );
};

const Summary = () => (
  <div className="AccountSummary">
    <FlexContainer justifyContent="space-between">
      <span className="AccountSummaryLabel">Total payment</span>
      <span className="AccountSummaryValue">SEK 1.00</span>
    </FlexContainer>
    <FlexContainer justifyContent="space-between">
      <span className="AccountSummaryLabel">Paying to</span>
      <span className="AccountSummaryValue">
        Svenska Sjöräddningssällskapet
      </span>
    </FlexContainer>
  </div>
);

const RecurringSummary = () => (
  <div className="ReccuringSummary">
    <span className="ReccuringSummaryContent">
      <span className="ReccuringSummaryThin">{"Pay "}</span>
      <span className="ReccuringSummaryThick">{"SEK 1.00 "}</span>
      <span className="ReccuringSummaryThin">{"to "}</span>
      <span className="ReccuringSummaryThick">
        {"Svenska Sjöräddningssällskapet, every month "}
      </span>
      <span className="ReccuringSummaryThin">{"until "}</span>
      <span className="ReccuringSummaryThick">{getLastRecurringDate()}</span>
    </span>
  </div>
);

const getLastRecurringDate = () => {
  let date = new Date();
  let lastDate = new Date(date.setMonth(date.getMonth() + 3));

  let day = String(lastDate.getDate()).padStart(2, "0");
  day = day[0] === "0" ? day[1] : day;
  return (
    monthNames[lastDate.getMonth()] + " " + day + " " + lastDate.getFullYear()
  );
};

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const Account = ({ account, selected, onClick }) => (
  <FlexContainer
    alignItems="center"
    onClick={onClick}
    className={`Account ${selected && " AccountSelected"}`}
    justifyContent="space-between"
  >
    <FlexContainer flexDirection="column">
      <span
        className={`AccountName ${
          selected ? " AccountSelectedFont" : "AccountNonSelectedFont"
        }`}
      >
        {account.name}
      </span>
      <FlexContainer justifyContent="space-between" margin="14px 0 0 0">
        <span
          className={`AccountNumber ${
            selected ? " AccountSelectedFont" : "AccountNonSelectedFont"
          }`}
        >
          {`******${getBbanFormatted(account)}`}
        </span>
      </FlexContainer>
    </FlexContainer>
    <span
      className={`AccountName ${
        selected ? " AccountSelectedFont" : "AccountNonSelectedFont"
      }`}
    >
      {getAmountText(account)}
    </span>
  </FlexContainer>
);

const getAmountText = ({ balance, currency }) =>
  `${currency} ${formatAmount(balance)}`;

const formatAmount = (amount) => {
  if (isNaN(amount)) return amount;
  return (Math.round(amount * 100) / 100).toFixed(2);
};

const getBbanFormatted = (account) =>
  `******${
    !account.bban && !!account.plusgiroNumber
      ? account.plusgiroNumber.substring(4)
      : account.bban.substring(6)
  }`;
