import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { FlexContainer } from "../../components/FlexContainer/FlexContainer";
import { Button } from "../../components/Button/Button";
import { Spinner } from "../../components/Loader/Loader";
import "./Pnr.css";
import { isMobile } from "../../utils/functions";
import usePrevious from "../../hooks/usePrevious";
import { PageContainer } from "../../components/PageContainer/PageContainer";
import { PageHeader } from "../../components/PageHeader/PageHeader";
import "./Pnr.css";
import { Header } from "../../components/Header/Header";
import useStore, { DemoStore } from "../../hooks/useStore";
import { AxiosHeader, Product, ReactQueryStatus } from "../../utils/enums";
import { addHeader } from "../../utils/axios";

export const Pnr = ({ onPnrEntered, createConsentStatus }) => {
  const {
    product,
    setPnr,
    pnrForAccountInStorage,
    setPnrPrev,
    bankName,
  } = useStore((state: DemoStore) => state);

  const [value, setValue] = useState(
    product !== Product.AccountVerification ? pnrForAccountInStorage || "" : ""
  );
  const [errorMessage, setErrorMessage] = useState<string>();
  const history = useHistory();

  const prevValue = usePrevious(value);

  useEffect(() => {
    if (createConsentStatus === ReactQueryStatus.Success) {
      history.push(isMobile() ? "consentopenbankid" : "qr");
    }
  }, [createConsentStatus, history]);

  // Remove error message when value changes
  useEffect(() => {
    if (prevValue !== value && !!errorMessage) {
      setErrorMessage(null);
    }
  }, [value, prevValue, errorMessage]);

  const onNextClick = () => {
    const trimmedInput = trimInput(value);
    if (!inputIsValid(trimmedInput)) {
      setErrorMessage("Incorrect format");
      return;
    }
    setPnr(trimmedInput);
    setPnrPrev(trimmedInput);
    addHeader(AxiosHeader.Pnr, trimmedInput);
    onPnrEntered();
  };

  const trimInput = (input) => input.replace(" ", "").replace("-", "");
  const inputIsValid = (input) => input.length === 12 && !isNaN(input);
  const whenKeyPressed = (e) => e.key === "Enter" && onNextClick();

  if (createConsentStatus === ReactQueryStatus.Loading) {
    return <Spinner text={`Connecting to ${bankName}`} />;
  }

  return (
    <>
      <Header showBankInHeader onBackClick={() => history.push("/banks")} />
      <PageContainer>
        <PageHeader text={`Log in to ${bankName}`} />
        <FlexContainer
          flexDirection="column"
          justifyContent="space-between"
          className="PnrContainer"
        >
          <FlexContainer flexDirection="column">
            <span className="PnrSubText">Social security number</span>
            <input
              value={value}
              onChange={(e) => setValue(e.target.value)}
              autoFocus
              placeholder="YYYYMMDDXXXX"
              onKeyDown={(e) => whenKeyPressed(e)}
              className={
                inputIsValid(trimInput(value))
                  ? "PnrInputCompleted"
                  : "PnrInput"
              }
            />
            <span className="PnrHelperText">
              To connect your bank, you need to identify yourself using Mobile
              BankID.
            </span>
          </FlexContainer>
          <FlexContainer justifyContent="center" alignItems="center">
            <Button
              text="Continue"
              onClick={onNextClick}
              disabled={!inputIsValid(trimInput(value))}
            />
          </FlexContainer>
        </FlexContainer>
      </PageContainer>
    </>
  );
};
