import { getAccountInformation } from "../../api/endpoints";
import { useQuery } from "react-query";
import { Spinner } from "../../components/Loader/Loader";
import { useEffect, useState } from "react";
import { ErrorMessage } from "../../components/ErrorMessage/ErrorMessage";
import { FlexContainer } from "../../components/FlexContainer/FlexContainer";
import { PageContainer } from "../../components/PageContainer/PageContainer";
import "./AccountInformation.css";
import { Header } from "../../components/Header/Header";
import { useTransition, config, animated, useSpring } from "react-spring";
import { getEndpointTimestamp } from "../../utils/functions";
import useStore, { DemoStore } from "../../hooks/useStore";
import { Product } from "../../utils/enums";

const ACCOUNTS = "Accounts";
const TRANSACTIONS = "Transactions";

export const AccountInformation = () => {
  const [currentTab, setCurrentTab] = useState(ACCOUNTS);
  const addApiCall = useStore((state: DemoStore) => state.addApiCall);
  const scope = useStore((state: DemoStore) => state.scope);

  const { data, isLoading, refetch, isError } = useQuery(
    "getAccountInformation",
    () => getAccountInformation(),
    {
      enabled: false,
    }
  );

  useEffect(() => (refetch as any)(), [refetch]);

  useEffect(() => {
    if (data) {
      if (Array.isArray(data.accounts)) {
        addApiCall({
          endpoint: "Get Account List",
          timestamp: getEndpointTimestamp(),
          statusCode: "200",
        });
        let transactionEndpoint = [];
        for (let i = 0; i < data.accounts.length; i++) {
          transactionEndpoint.push({
            endpoint: "Get Transaction List",
            timestamp: getEndpointTimestamp(),
            statusCode: "200",
          });
        }
        addApiCall(transactionEndpoint);
      }
    }
  }, [data, addApiCall]);

  if (isLoading) {
    return <Spinner text="Loading account data" />;
  }

  if (isError || !data) {
    return <ErrorMessage text="Account information unavailable" />;
  }
  let { accounts, transactions } = data;

  accounts = accounts.map((acc) => ({
    ...acc,
    balance: getSmallestBalance(acc.balances),
  }));

  const showAccounts = (accounts, tab) =>
    !!accounts && accounts.length > 0 && tab === ACCOUNTS;
  const showTransactions = (transvestites, tab) =>
    !!transvestites && transvestites.length > 0 && tab === TRANSACTIONS;

  const isCorporate = scope === "corporate";

  return (
    <>
      <Header headline={Product.AccountInformation} />
      <FlexContainer justifyContent="space-between">
        <Tab
          onTabClick={() => setCurrentTab(ACCOUNTS)}
          text={ACCOUNTS}
          selected={currentTab === ACCOUNTS}
        />
        <Tab
          onTabClick={() => setCurrentTab(TRANSACTIONS)}
          text={TRANSACTIONS}
          selected={currentTab === TRANSACTIONS}
        />
      </FlexContainer>
      <PageContainer paddingRight="0" paddingTop="0">
        {showAccounts(accounts, currentTab) ? (
          <FlexContainer
            className="TransactionsList"
            flexDirection="column"
            justifyContent="space-between"
          >
            <FlexContainer flexDirection="column">
              {accounts.map((acc) => (
                <Account key={acc.id} account={acc} isCorporate={isCorporate} />
              ))}
            </FlexContainer>
            {isCorporate && (
              <FlexContainer flexDirection="column" justifyContent="flex-start">
                <span className="TransactionAccountNumber">Account owner</span>
                <span className="TransactionAccountName">
                  {accounts[0].ownerName}
                </span>
              </FlexContainer>
            )}
          </FlexContainer>
        ) : (
          showTransactions(transactions, currentTab) && (
            <FlexContainer className="TransactionsList" flexDirection="column">
              <TransactionList transactions={transactions} />
            </FlexContainer>
          )
        )}
      </PageContainer>
    </>
  );
};

const TransactionList = ({ transactions }) => {
  // This warning can be ignored
  const transitions = useTransition(transactions, (item, i) => i, {
    config: config.stiff,
    from: { opacity: 0, transform: "translate3d(-25%, 0px, 0px)" },
    enter: { opacity: 1, transform: "translate3d(0%, 0px, 0px)" },
    trail: 40,
  });
  return (
    <>
      {transitions.map(({ item: transaction, props, key }) => (
        <animated.div className="TransactionsListItem" style={props} key={key}>
          <span className="TransactionsListItemInfo">{transaction.ocr}</span>
          <FlexContainer
            flexDirection="column"
            alignItems="center"
            className="RightSide"
          >
            <span className="TransactionsListItemAmount">
              {getAmountText(transaction)}
            </span>
            <span className="TransactionsListItemDate">
              {transaction.valueDate}
            </span>
          </FlexContainer>
        </animated.div>
      ))}
    </>
  );
};

const Account = ({ account, isCorporate }) => {
  const props = useSpring({
    from: { opacity: 0, transform: "translateY(50px)" },
    to: { opacity: 1, transform: "translateY(0)" },
    config: config.default,
  });
  return (
    <animated.div className="TransactionsListItem" style={props}>
      <FlexContainer flexDirection="column" alignItems="flex-start">
        <span className="TransactionAccountName">
          {isCorporate ? account.product : account.name}
        </span>
        <span className="TransactionAccountNumber">{account.bban}</span>
        <span className="TransactionAccountNumber">
          {formatIban(account.iban)}
        </span>
      </FlexContainer>
      <span className="TransactionsListItemInfo">
        {account.currency + " " + formatAmount(account.balance)}
      </span>
    </animated.div>
  );
};

const Tab = ({ text, selected, onTabClick }) => (
  <div
    className={
      selected ? "AccountInformationTabSelected" : "AccountInformationTab"
    }
    onClick={onTabClick}
  >
    {text}
  </div>
);

const formatAmount = (amount) => {
  if (isNaN(amount)) return amount;
  return (Math.round(amount * 100) / 100).toFixed(2);
};

const getSmallestBalance = (balances) =>
  Math.min(...balances.map((balance) => balance.balanceAmount.amount));

const formatIban = (iban) => {
  let split = iban.match(/.{1,4}/g);
  return split.join(" ");
};

const getAmountText = (tran) =>
  isNegative(tran)
    ? `- ${tran.currency} ${formatAmount(tran.amount.replace("-", ""))}`
    : `${tran.currency} ${formatAmount(tran.amount)}`;

const isNegative = (tran) => +tran.amount < 0;
