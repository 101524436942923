import React from "react";
import { FlexContainer } from "../../../components/FlexContainer/FlexContainer";
import "./UseCase.css";

interface Props {
  icon: string;
  iconSmall: string;
  header: string;
  text: string;
  docsLink?: string;
  onActionClick: () => void;
}

export const UseCase: React.FC<Props> = ({
  icon,
  header,
  text,
  docsLink,
  onActionClick,
  iconSmall,
}) => {
  return (
    <>
      <div className="UseCase">
        <FlexContainer justifyContent="center" alignItems="center">
          <div className="UseCaseIconWrapper">
            <img
              src={icon}
              alt={header}
              className="UseCaseIcon"
              onClick={onActionClick}
            />
          </div>
          <div className="UseCaseIconWrapperSmall">
            <img src={iconSmall} alt={header} className="UseCaseIconSmall" />
          </div>
        </FlexContainer>
        <span className="UseCaseHeader" onClick={onActionClick}>
          {header}
        </span>
        <span className="UseCaseText">{text}</span>
        <FlexContainer justifyContent="space-between" alignItems="center">
          <button className="UseCaseAction" onClick={onActionClick}>
            Demo
          </button>
          {docsLink && (
            <a
              className="UseCaseDocsLink"
              href={docsLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              Docs
            </a>
          )}
        </FlexContainer>
      </div>
      <div className="UseCaseSmall" onClick={onActionClick}>
        <FlexContainer justifyContent="center" alignItems="center">
          <div className="UseCaseIconWrapperSmall">
            <img src={iconSmall} alt={header} className="UseCaseIconSmall" />
          </div>
        </FlexContainer>
        <span className="UseCaseHeader">{header}</span>
      </div>
    </>
  );
};
