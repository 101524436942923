import React, { useEffect } from "react";
import { ApiCall } from "./ApiCall/ApiCall";
import { MultipleApiCalls } from "./MultipleApiCalls";
import "./ApiTerminal.css";
import useStore, { DemoStore } from "../../hooks/useStore";

export const ApiTerminal = () => {
  const apiCalls = useStore((state: DemoStore) => state.apiCalls);
  const closeApiTerminal = useStore(
    (state: DemoStore) => state.closeApiTerminal
  );

  const scrollToBottom = () => {
    var element = document.getElementById("ApiTerminalContent");
    element.scrollTop = element.scrollHeight;
  };
  useEffect(() => {
    scrollToBottom();
  }, [apiCalls]);

  return (
    <div className="ApiTerminal">
      <img
        src={`close.svg`}
        onClick={closeApiTerminal}
        alt="back"
        className="ApiTerminalPauseButton"
      />

      <div id="ApiTerminalContent" className="ApiTerminalContent">
        <span className="Color-White loading-dots">
          {"> Waiting for requests"}
        </span>
        {apiCalls.map((ep, index) =>
          Array.isArray(ep) ? (
            <MultipleApiCalls key={index} endpoints={ep} />
          ) : (
            <ApiCall
              key={index}
              endpoint={ep.endpoint}
              statusCode={ep.statusCode}
              timestamp={ep.timestamp}
            />
          )
        )}
      </div>
    </div>
  );
};
