import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "../../components/Button/Button";
import { FlexContainer } from "../../components/FlexContainer/FlexContainer";
import { Header } from "../../components/Header/Header";
import { PageContainer } from "../../components/PageContainer/PageContainer";
import { PageHeader } from "../../components/PageHeader/PageHeader";
import useStore, { DemoStore } from "../../hooks/useStore";
import { Product } from "../../utils/enums";
import "./DonationInfo.css";

export const DonationInfo = () => {
  const product = useStore((store: DemoStore) => store.product);
  const history = useHistory();
  const isInvoice = product === Product.Invoice;

  return (
    <>
      <Header onBackClick={() => history.push("/")} />
      <PageContainer>
        <PageHeader text="Information" />
        <p>
          {`By testing our ${
            isInvoice ? `payment link` : `PIS`
          } demo you will donate a small amount to charity. We
          have selected Swedish Sea Rescue Society.`}
        </p>
        <img src="sjoraddningsallskapet.png" />
        <FlexContainer alignItems="center" justifyContent="center">
          <span className="DonationInfoAmount">SEK 1.00</span>
        </FlexContainer>
        <Button
          text="Start"
          onClick={() => history.push(isInvoice ? "/invoiceadmin" : "/scope")}
        />
      </PageContainer>
    </>
  );
};
