export enum ScaMethod {
  Decoupled = "SCA_METHOD_DECOUPLED",
  Redirect = "SCA_METHOD_REDIRECT",
  OAuthRedirect = "SCA_METHOD_OAUTH_REDIRECT",
}

export enum Product {
  PaymentInitiation = "Payment",
  AccountInformation = "Account information",
  RecurringPayment = "Recurring payment",
  AccountVerification = "Account verification",
  Invoice = "invoice",
}

export enum PaymentService {
  Payments = "payments",
  Recurring = "periodic-payments",
}

export enum BankId {
  HANDSESS = "1",
  ESSESESS = "2",
  SWEDSESS = "3",
  NDEASESS = "4",
  DABASESX = "5",
  SPARBANKERNA = "SPARBANKERNA",
}

export enum Scopes {
  Private = "private",
  Corporate = "corporate",
}

export enum AxiosHeader {
  Pnr = "pnr",
  BankId = "bankid",
  Sparbank = "sparbank",
  PaymentService = "payment-service",
  Scope = "scope",
  CompanyId = "companyid",
}

export enum ScaStatus {
  AuthenticationStarted = "authenticationStarted",
  AuthoriseCreditorAccountStarted = "authoriseCreditorAccountStarted",
  Exempted = "exempted",
  Failed = "failed",
  Finalised = "finalised",
  PsuAuthenticated = "psuAuthenticated",
  Rejected = "rejected",
  Started = "started",
  Success = "success",
}

export enum ReactQueryStatus {
  Success = "success",
  Error = "error",
  Loading = "loading",
  Idle = "idle",
}

export enum InvoiceStatusEnum {
  Created = "Created",
  Received = "Received",
  PaymentStarted = "Payment started",
  PaymentCompleted = "Payment completed",
}
