import React from "react";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { Route } from "react-router-dom";
import useStore, { DemoStore } from "../../hooks/useStore";
import { animated, config, useSpring } from "react-spring";
import ApiTerminal from "../ApiTerminal";
import "./DemoRoute.css";
interface Props {
  path: string;
  exact?: boolean;
  children: React.ReactNode;
}

export const DemoRoute: React.FC<Props> = ({
  exact = false,
  path,
  children,
}) => {
  const showApiTerminal = useStore((state: DemoStore) => state.showApiTerminal);
  const toggleApiTerminal = useStore(
    (state: DemoStore) => state.toggleApiTerminal
  );

  const appStyles = useSpring({
    transform: showApiTerminal
      ? "translate(-220px, 0px)"
      : "translate(0px, 0px)",
    config: config.default,
  });

  const apiTerminalStyles = useSpring({
    transform: showApiTerminal
      ? "translate(0px, -150px)"
      : "translate(-220px, -150px)",
    config: config.default,
  });

  return (
    <Route exact={exact} path={path}>
      <FlexContainer
        className="AppWrapper"
        justifyContent="center"
        alignItems="center"
      >
        <animated.div className="App" style={appStyles}>
          <div className="AppContentWrapper">{children}</div>
        </animated.div>

        {showApiTerminal && (
          <animated.div style={apiTerminalStyles}>
            <ApiTerminal />
          </animated.div>
        )}

        <div className="ShowApiCalls">
          <input
            type="checkbox"
            className="ShowApiCallsCheckbox"
            onChange={toggleApiTerminal}
            checked={showApiTerminal}
          />
          <label className="ShowApiCallsLabel" onClick={toggleApiTerminal}>
            Show API calls
          </label>
        </div>
      </FlexContainer>
    </Route>
  );
};
