import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useMutation } from "react-query";
import DatePicker from "react-datepicker";
import { createInvoice } from "../../../api/endpoints";
import { Header } from "../../../components/Header/Header";
import { PageContainer } from "../../../components/PageContainer/PageContainer";
import useStore, { DemoStore } from "../../../hooks/useStore";
import { PageHeader } from "../../../components/PageHeader/PageHeader";
import { Field } from "../InvoiceLanding/Field/Field";
import { FlexContainer } from "../../../components/FlexContainer/FlexContainer";
import { Spinner } from "../../../components/Loader/Loader";
import {
  addDays,
  addMonths,
  dateIsWeekday,
  getNextWeekday,
  toDate,
} from "../../../utils/functions";
import { PaymentService } from "../../../utils/enums";
import "./InvoiceAdmin.css";

const AMOUNTS = ["1", "5"];
const RECURRING_TIMES = ["3", "4", "5"];
const PAYMENT_TYPES = [
  { id: PaymentService.Payments, name: "Single payment" },
  { id: PaymentService.Recurring, name: "Recurring payment" },
];
export const InvoiceAdmin = () => {
  const history = useHistory();
  const [amount, setAmount] = useState(AMOUNTS[0]);
  const [paymentType, setPaymentType] = useState(PAYMENT_TYPES[0]);
  const [recurringStartDate, setRecurringStartDate] = useState(
    getNextWeekday()
  );
  const [recurringTimes, setRecurringTimes] = useState(RECURRING_TIMES[0]);
  const [message, setMessage] = useState("");

  const { setInvoiceID, setSelectedPaymentService } = useStore(
    (store: DemoStore) => store
  );

  const {
    mutate,
    data: createdInvoice,
    isLoading,
    reset,
  } = useMutation((data: any) => createInvoice(data));

  useEffect(() => {
    setInvoiceID(null);
    reset();
  }, []);

  useEffect(() => {
    if (createdInvoice) {
      history.push(`/invoiceqr?id=${createdInvoice.id}`);
    }
  }, [createdInvoice]);

  // this sets the correct header
  useEffect(() => setSelectedPaymentService(paymentType.id), [paymentType]);

  const isPeriodicPaymentsSelected = () =>
    paymentType.id === PaymentService.Recurring;

  // This makes number of payments = 3
  // If recurringStartDate is February 20, then recurringEndDate gets
  // May 5, meaning that the last payment will be on April 20,
  // i.e. three payments
  const getRecurringEndDate = () =>
    toDate(addDays(-15, addMonths(recurringStartDate, +recurringTimes)));

  if (isLoading) {
    return <Spinner text="Creating payment link" />;
  }

  return (
    <>
      <Header onBackClick={() => history.push("/")} />
      <PageContainer>
        <FlexContainer
          flexDirection="column"
          justifyContent="space-between"
          fullHeight
        >
          <FlexContainer flexDirection="column">
            <PageHeader text="Create QR Request-to-pay" />
            <Field
              name="Type"
              component={
                <select
                  name="paymenttype"
                  value={paymentType.id}
                  onChange={(e: any) =>
                    setPaymentType(
                      PAYMENT_TYPES.find((pt) => pt.id === e.target.value)
                    )
                  }
                  className="PaymentTypeSelect"
                >
                  {PAYMENT_TYPES.map((pt) => (
                    <option
                      key={pt.id}
                      value={pt.id}
                      className="PaymentTypeOption"
                    >
                      {pt.name}
                    </option>
                  ))}
                </select>
              }
            />
            {isPeriodicPaymentsSelected() && (
              <>
                <Field
                  name="First payment date"
                  icon="calendar_icon.svg"
                  component={
                    <DatePicker
                      selected={recurringStartDate}
                      onChange={setRecurringStartDate}
                      filterDate={dateIsWeekday}
                      minDate={getNextWeekday()}
                      placeholderText="Select first payment date"
                    />
                  }
                />
                <Field
                  name="Number of payments"
                  component={
                    <select
                      name="recurringtimes"
                      value={recurringTimes}
                      onChange={(e: any) => setRecurringTimes(e.target.value)}
                      className="PaymentTypeSelect"
                    >
                      {RECURRING_TIMES.map((time) => (
                        <option
                          key={time}
                          value={time}
                          className="PaymentTypeOption"
                        >
                          {time}
                        </option>
                      ))}
                    </select>
                  }
                />
              </>
            )}

            <Field
              name={
                isPeriodicPaymentsSelected() ? "Amount per payment" : "Amount"
              }
              component={
                <select
                  name="amount"
                  value={amount}
                  onChange={(e: any) => setAmount(e.target.value)}
                  className="PaymentTypeSelect"
                >
                  {AMOUNTS.map((am) => (
                    <option
                      key={am}
                      value={am}
                      className="PaymentTypeOption"
                    >{`SEK ${am}.00`}</option>
                  ))}
                </select>
              }
            />

            <Field
              name="Message (optional)"
              component={
                <textarea
                  value={message}
                  onChange={(e: any) => setMessage(e.target.value)}
                  className="InvoiceMessageInput"
                />
              }
            />
          </FlexContainer>
          <button
            className="CreateInvoiceButton"
            onClick={() =>
              mutate({
                amount,
                paymentType,
                recurringStartDate: toDate(recurringStartDate),
                recurringEndDate: getRecurringEndDate(),
                message,
              })
            }
          >
            Create Link
          </button>
        </FlexContainer>
      </PageContainer>
    </>
  );
};
