import React, { useEffect } from "react";
import { Spinner } from "../../components/Loader/Loader";
import { getPaymentStatus } from "../../api/endpoints";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import useStore, { DemoStore } from "../../hooks/useStore";
import { Product, ScaStatus } from "../../utils/enums";
import { ErrorMessage } from "../../components/ErrorMessage/ErrorMessage";
import { parseErrorMessage } from "../../utils/reactQuery";

export const PaymentOpenBankID = () => {
  const {
    confirmPaymentBankIdLink,
    isFromChooseAccount,
    setIsFromChooseAccount,
    product,
  } = useStore((state: DemoStore) => state);

  const history = useHistory();

  const { data, error } = useQuery(
    "getPaymentStatus",
    () => getPaymentStatus(),
    {
      refetchInterval: 2000,
      enabled: !isFromChooseAccount,
      cacheTime: 0,
    }
  );

  useEffect(() => {
    if (!!confirmPaymentBankIdLink && isFromChooseAccount) {
      setIsFromChooseAccount(false);
      (window as any).location = confirmPaymentBankIdLink;
    }
  }, [confirmPaymentBankIdLink, isFromChooseAccount, setIsFromChooseAccount]);

  useEffect(() => {
    if (hasFinalised(data)) {
      if (product === Product.Invoice) {
        history.push("/invoicesuccess");
      } else {
        history.push("/success");
      }
    } else if (hasStarted(data)) {
      window.location =
        data.confirmPaymentBankIdLink || "bankid:///?autostarttoken=&redirect=";
    }
  }, [data, history]);

  const getSpinnerText = () => "Just one moment";

  if (!!error) {
    return <ErrorMessage text={parseErrorMessage(error)} />;
  }

  return <Spinner text={getSpinnerText()} />;
};

const hasStarted = (data: any) => {
  if (!data || !data.scaStatus) return false;
  return (
    data.scaStatus === ScaStatus.AuthenticationStarted ||
    data.scaStatus === ScaStatus.AuthoriseCreditorAccountStarted ||
    data.scaStatus === ScaStatus.Started
  );
};

const hasFinalised = (data: any) => {
  if (!data || !data.scaStatus) return false;
  return (
    data.scaStatus === ScaStatus.Finalised ||
    data.scaStatus === ScaStatus.Exempted
  );
};
