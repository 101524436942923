import { useHistory, useLocation } from "react-router-dom";
import { useMutation } from "react-query";
import { finalizePaymentAfterRedirect } from "../../api/endpoints";
import { useEffect } from "react";
import { Spinner } from "../../components/Loader/Loader";
import { ErrorMessage } from "../../components/ErrorMessage/ErrorMessage";
import useStore, { DemoStore } from "../../hooks/useStore";
import { getEndpointTimestamp } from "../../utils/functions";
import { addHeader } from "../../utils/axios";

export const PaymentRedirectLanding = () => {
  let query = useQuery();
  const history = useHistory();
  const addApiCall = useStore((state: DemoStore) => state.addApiCall);
  const _scope = useStore((state: DemoStore) => state.scope);

  const { mutate, isSuccess, isError } = useMutation((data: any) =>
    finalizePaymentAfterRedirect(data)
  );

  useEffect(() => {
    const redirect_uri = window.location.href.split("?")[0];
    const code = query.get("code");
    const scope = query.get("scope");
    addHeader("scope", _scope);
    addApiCall({
      endpoint: "Activate OAuth payment authorisation",
      statusCode: "200",
      timestamp: getEndpointTimestamp(),
    });
    mutate({ code, scope, redirect_uri });
  }, []);

  useEffect(() => {
    if (isSuccess) {
      history.push("/success");
    }
  }, [isSuccess, history]);

  if (isError) {
    return <ErrorMessage />;
  }

  return <Spinner text="Just one moment" />;
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
