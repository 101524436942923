import React from "react";
import "./Status.css";

interface Props {
  status: "Unpaid" | "Paid";
}

export const Status: React.FC<Props> = ({ status }) => {
  return <span className="InvoiceStatus">{status}</span>;
};
