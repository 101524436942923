import React from "react";
import "./ChooseSparbank.css";
import { PageHeader } from "../../components/PageHeader/PageHeader";
import { Header } from "../../components/Header/Header";
import { useHistory } from "react-router-dom";
import useStore, { DemoStore } from "../../hooks/useStore";
import { AxiosHeader, BankId } from "../../utils/enums";
import { addHeader } from "../../utils/axios";

export const ChooseSparbank = ({ afterBankClick }) => {
  const { sparbankerna, setSelectedSparbank } = useStore(
    (state: DemoStore) => state
  );

  const history = useHistory();

  const onBankClick = (sparbank) => {
    setSelectedSparbank(sparbank.id);
    addHeader(AxiosHeader.Sparbank, sparbank.id);
    afterBankClick(BankId.SWEDSESS, sparbank.name);
  };

  return (
    <>
      <Header onBackClick={() => history.push("/banks")} showBankInHeader />
      <div className="ChooseSparbankRoot">
        <PageHeader text="Select Sparbank" />
        <div className="ChooseSparbankList">
          {sparbankerna.map((bank, index) => (
            <ListItem
              key={bank.id}
              name={bank.name}
              even={index % 2 === 0}
              onClick={() => onBankClick(bank)}
            />
          ))}
        </div>
      </div>
    </>
  );
};

const ListItem = ({ name, even, onClick }) => (
  <span
    className={`SparbankListItem ${
      even ? "SparbankListItemLight" : "SparbankListItemDark"
    }`}
    onClick={onClick}
  >
    {name}
  </span>
);
