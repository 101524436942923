import { useCallback, useEffect } from "react";
import { useMutation } from "react-query";
import { useHistory, useLocation } from "react-router-dom";
import { finaliseConsentAfterRedirect } from "../../api/endpoints";
import { Spinner } from "../../components/Loader/Loader";
import { Product, ScaMethod } from "../../utils/enums";
import { ErrorMessage } from "../../components/ErrorMessage/ErrorMessage";
import useStore, { DemoStore } from "../../hooks/useStore";
import { getEndpointTimestamp } from "../../utils/functions";

export const ConsentRedirectLanding = () => {
  let query = useQuery();
  const history = useHistory();
  const { addApiCall, scaMethod, product } = useStore(
    (state: DemoStore) => state
  );

  const { mutate, isSuccess, isError } = useMutation((data: any) =>
    finaliseConsentAfterRedirect(data)
  );

  const navigateToNextScreen = useCallback(() => {
    switch (product) {
      case Product.PaymentInitiation:
      case Product.RecurringPayment:
        history.push("/accounts");
        break;
      case Product.AccountInformation:
        history.push("/ais");
        break;
      case Product.AccountVerification:
        history.push("/accountverification");
        break;
      default:
        break;
    }
  }, [history, product]);

  useEffect(() => {
    if (scaMethod === ScaMethod.Redirect) {
      // Redirect does not require finalisation of the consent
      navigateToNextScreen();
    } else if (scaMethod === ScaMethod.OAuthRedirect) {
      // Sca method is Oauth Redirect, the consent needs to be finalised.
      const code = query.get("code");
      const scope = query.get("scope");
      const redirect_uri = window.location.href.split("?")[0];
      addApiCall({
        endpoint: "Activate OAuth consent authorisation",
        statusCode: "200",
        timestamp: getEndpointTimestamp(),
      });
      mutate({ code, scope, redirect_uri });
    }
  }, []);

  useEffect(() => {
    if (isSuccess) {
      navigateToNextScreen();
    }
  }, [isSuccess, navigateToNextScreen]);

  if (isError) {
    return <ErrorMessage />;
  }

  return <Spinner text="Loading accounts" />;
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
