import React, { useEffect, useState } from "react";
import "./App.css";
import {
  AxiosHeader,
  BankId,
  Product,
  ReactQueryStatus,
  ScaMethod,
} from "./utils/enums";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Banks } from "./pages/Banks/Banks";
import { ConsentQr } from "./pages/ConsentQr/ConsentQr";
import { Accounts } from "./pages/Accounts/Accounts";
import { PaymentSuccessful } from "./pages/PaymentSuccessful/PaymentSuccessful";
import { generateConsent } from "./api/endpoints";
import { useMutation } from "react-query";
import { Spinner } from "./components/Loader/Loader";
import { PaymentQr } from "./pages/PaymentQr/PaymentQr";
import { AccountVerification } from "./pages/AccountVerification/AccountVerification";
import { Verified } from "./pages/AccountVerification/Verified";
import { ConsentRedirectLanding } from "./pages/ConsentRedirectLanding/ConsentRedirectLanding";
import { PaymentRedirectLanding } from "./pages/PaymentRedirectLanding/PaymentRedirectLanding";
import { AccountInformation } from "./pages/AccountInformation/AccountInformation";
import { Scope } from "./pages/Scope/Scope";
import { CompanyId } from "./pages/Company/CompanyId";
import { ErrorMessage } from "./components/ErrorMessage/ErrorMessage";
import { Pnr } from "./pages/Pnr/Pnr";
import { ConsentOpenBankID } from "./pages/ConsentOpenBankID/ConsentOpenBankID";
import { PaymentOpenBankID } from "./pages/PaymentOpenBankID/PaymentOpenBankID";
import { ChooseSparbank } from "./pages/Sparbank/ChooseSparbank";
import { AccountVerificationFailed } from "./pages/AccountVerification/AccountVerificationFailed";
import { isAndroid, isMobile } from "./utils/functions";
/* eslint-disable */
import useStore, { DemoStore } from "./hooks/useStore";
import { getEndpointTimestamp } from "./utils/functions";
import { FlexContainer } from "./components/FlexContainer/FlexContainer";
import InvoiceLanding from "./pages/Invoice/InvoiceLanding";
import InvoiceAccounts from "./pages/InvoiceAccounts";
import InvoiceSuccessful from "./pages/InvoiceSuccessful";
import LandingPage from "./pages/LandingPage";
import { DemoRoute } from "./components/DemoRoute/DemoRoute";
import InvoiceAdmin from "./pages/Invoice/InvoiceAdmin";
import InvoiceQr from "./pages/Invoice/InvoiceQR";
import InvoiceStatus from "./pages/InvoiceStatus";
import "./components/DemoRoute/DemoRoute.css";
import { DonationInfo } from "./pages/DonationInfo/DonationInfo";
import { addHeader, removeHeader } from "./utils/axios";
import { parseErrorMessage } from "./utils/reactQuery";

function App() {
  const [isRecreatingConsent, setIsRecreatingConsent] = useState(false);
  const [pnrEnteredSuccessfully, setPnrEnteredSuccessfully] = useState(false);

  const {
    addApiCall,
    setBankName,
    bankName,
    bankID,
    selectedPaymentService,
    selectedSparbank,
    setBankID,
    setScaMethod,
    setBankIdLink,
    product,
    pnr,
    setQrImage,
    scope,
    companyId,
  } = useStore((state: DemoStore) => state);

  const {
    data: createConsentData,
    mutate: createConsent,
    status: createConsentStatus,
    isError,
    error: createConsentError,
    reset: resetConsentMutation,
  } = useMutation((body: any) => generateConsent(body));

  const recreateConsent = () => {
    setIsRecreatingConsent(true);
    createConsent({ isMobile: isMobile() });
  };

  // The following useEffects listens a variable in the store and sets the correct axios header when it changes
  useEffect(() => {
    if (pnr) {
      addHeader(AxiosHeader.Pnr, pnr);
    } else {
      removeHeader(AxiosHeader.Pnr);
    }
  }, [pnr]);

  useEffect(() => {
    if (bankID) {
      addHeader(AxiosHeader.BankId, bankID);
    } else {
      removeHeader(AxiosHeader.BankId);
    }
  }, [bankID]);

  useEffect(() => {
    if (selectedSparbank) {
      addHeader(AxiosHeader.Sparbank, selectedSparbank);
    } else {
      removeHeader(AxiosHeader.Sparbank);
    }
  }, [selectedSparbank]);

  useEffect(() => {
    if (selectedPaymentService) {
      addHeader(AxiosHeader.PaymentService, selectedPaymentService);
    } else {
      removeHeader(AxiosHeader.PaymentService);
    }
  }, [selectedPaymentService]);

  useEffect(() => {
    if (scope) {
      addHeader(AxiosHeader.Scope, scope);
    } else {
      removeHeader(AxiosHeader.Scope);
    }
  }, [scope]);

  useEffect(() => {
    if (companyId) {
      addHeader(AxiosHeader.CompanyId, companyId);
    } else {
      removeHeader(AxiosHeader.CompanyId);
    }
  }, [companyId]);

  const onBankClick = (bankId, name) => {
    setBankName(name);
    setBankID(bankId);
    addHeader(AxiosHeader.BankId, bankId);
    addApiCall({
      endpoint: "Request Account Information token",
      statusCode: "200",
      timestamp: getEndpointTimestamp(),
    });
    if (bankId === BankId.SWEDSESS || product === Product.AccountVerification) {
      // Be om personnummer
      window.location.href = "/pnr";
    } else {
      createConsent({ isMobile: isMobile() });
    }
  };

  useEffect(() => {
    if (pnrEnteredSuccessfully) {
      createConsent({ isMobile: isMobile() });
    }
  }, [pnrEnteredSuccessfully, createConsent]);

  useEffect(() => {
    if (createConsentStatus === ReactQueryStatus.Success) {
      addApiCall([
        {
          endpoint: "Create Consent",
          statusCode: "201",
          timestamp: getEndpointTimestamp(),
        },
        {
          endpoint: "Start Consent Authorisation Process",
          statusCode: "201",
          timestamp: getEndpointTimestamp(),
        },
        {
          endpoint: "Update PSU Data for Consent",
          statusCode: "200",
          timestamp: getEndpointTimestamp(),
        },
      ]);
      if (isRecreatingConsent) {
        setIsRecreatingConsent(false);
      }
      let { scaMethod, qrCodeURI, qrImage } = createConsentData;
      setScaMethod(scaMethod);
      if (qrImage) {
        setQrImage(qrImage);
      }

      if (scaMethod === ScaMethod.Decoupled) {
        resetConsentMutation();
      }
      if (isMobile() && !!qrCodeURI && scaMethod === ScaMethod.Decoupled) {
        qrCodeURI = qrCodeURI.split("&")[0];
      }

      if (isAndroid()) {
        qrCodeURI = qrCodeURI.replace("bankid:///", "https://app.bankid.com/");
      }

      setBankIdLink(qrCodeURI);
    }
  }, [createConsentStatus, createConsentData, isRecreatingConsent]);

  const isRedirect = () =>
    !!createConsentData && createConsentData.scaMethod !== ScaMethod.Decoupled;

  const getRedirectUri = () =>
    !createConsentData ? null : createConsentData.scaOAuth;

  if (isRecreatingConsent) {
    return (
      <FlexContainer
        className="AppWrapper"
        justifyContent="center"
        alignItems="center"
      >
        <div className="App">
          <div className="AppContentWrapper">
            <Spinner text={`QR code expired, please wait`} />
          </div>
        </div>
      </FlexContainer>
    );
  }

  if (isError) {
    return (
      <FlexContainer
        className="AppWrapper"
        justifyContent="center"
        alignItems="center"
      >
        <div className="App">
          <div className="AppContentWrapper">
            <ErrorMessage text={parseErrorMessage(createConsentError)} />
          </div>
        </div>
      </FlexContainer>
    );
  }

  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <LandingPage />
        </Route>
        <DemoRoute path="/banks">
          <Banks
            onBankClick={onBankClick}
            createConsentStatus={createConsentStatus}
            loadingConsentText={`Connecting to ${bankName}`}
            isRedirect={isRedirect()}
            redirectUri={getRedirectUri()}
          />
        </DemoRoute>
        <DemoRoute path="/choosesparbank">
          <ChooseSparbank afterBankClick={onBankClick} />
        </DemoRoute>
        <DemoRoute path="/qr">
          <ConsentQr
            recreateConsent={recreateConsent}
            resetConsentMutation={resetConsentMutation}
            isConsentReset={!createConsentData}
          />
        </DemoRoute>
        <DemoRoute path="/accounts">
          <Accounts />
        </DemoRoute>
        <DemoRoute path="/confirmpayment">
          <PaymentQr />
        </DemoRoute>
        <DemoRoute path="/success">
          <PaymentSuccessful />
        </DemoRoute>
        <DemoRoute path="/redirectlanding">
          <ConsentRedirectLanding />
        </DemoRoute>
        <DemoRoute path="/paymentredirect">
          <PaymentRedirectLanding />
        </DemoRoute>
        <DemoRoute path="/ais">
          <AccountInformation />
        </DemoRoute>
        <DemoRoute path="/scope">
          <Scope />
        </DemoRoute>
        <DemoRoute path="/companyid">
          <CompanyId />
        </DemoRoute>
        <DemoRoute path="/pnr">
          <Pnr
            onPnrEntered={() => setPnrEnteredSuccessfully(true)}
            createConsentStatus={createConsentStatus}
          />
        </DemoRoute>
        <DemoRoute path="/consentopenbankid">
          <ConsentOpenBankID recreateConsent={recreateConsent} />
        </DemoRoute>
        <DemoRoute path="/paymentopenbankid">
          <PaymentOpenBankID />
        </DemoRoute>
        <DemoRoute path="/accountverification">
          <AccountVerification />
        </DemoRoute>

        <DemoRoute path="/verified">
          <Verified />
        </DemoRoute>
        <DemoRoute path="/accountverificationfailed">
          <AccountVerificationFailed />
        </DemoRoute>
        <DemoRoute path="/invoicelanding">
          <InvoiceLanding />
        </DemoRoute>
        <DemoRoute path="/invoiceaccounts">
          <InvoiceAccounts />
        </DemoRoute>
        <DemoRoute path="/invoicesuccess">
          <InvoiceSuccessful />
        </DemoRoute>
        <DemoRoute path="/invoiceadmin">
          <InvoiceAdmin />
        </DemoRoute>
        <DemoRoute path="/invoiceqr">
          <InvoiceQr />
        </DemoRoute>
        <DemoRoute path="/invoicestatus">
          <InvoiceStatus />
        </DemoRoute>
        <DemoRoute path="/donationinfo">
          <DonationInfo />
        </DemoRoute>
      </Switch>
    </Router>
  );
}

export default App;
