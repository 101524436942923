import { ErrorMessage } from "../../components/ErrorMessage/ErrorMessage";
import React from "react";
import { useHistory } from "react-router-dom";

export const AccountVerificationFailed = () => {
  const history = useHistory();
  return (
    <ErrorMessage
      text="Account verification failed"
      onRestartClick={() => history.push("/pnr")}
      restartText="Try again"
    />
  );
};
