import React, { useEffect } from "react";
import { FlexContainer } from "../../components/FlexContainer/FlexContainer";
import "./LandingPage.css";
import { UseCase } from "./UseCase/UseCase";
import { useHistory } from "react-router-dom";
import useStore, { DemoStore } from "../../hooks/useStore";
import { AxiosHeader, PaymentService, Product } from "../../utils/enums";
import { addHeader } from "../../utils/axios";

export const LandingPage = () => {
  const history = useHistory();
  const {
    resetStore,
    setBankName,
    setBankID,
    setProduct,
    setPnr,
    accountsInStorage,
    bankIdInStorage,
    bankNameInStorage,
    pnrForAccountInStorage,
    setSelectedPaymentService,
    setAccountsInStorage,
    setBankNameInStorage,
    setBankIdInStorage,
  } = useStore((state: DemoStore) => state);

  useEffect(() => {
    resetStore();
    // Clear session cookie
    document.cookie = "connect.sid=; Max-Age=-99999999;";
  }, []);

  const onPaymentClick = () => {
    setProduct(Product.PaymentInitiation);
    addHeader(AxiosHeader.PaymentService, PaymentService.Payments);
    setSelectedPaymentService(PaymentService.Payments);
    goToAccounts();
  };

  const onAccountInformationClick = () => {
    setProduct(Product.AccountInformation);
    history.push("/scope");
  };

  const onAccountVerificationClick = () => {
    setProduct(Product.AccountVerification);
    history.push("/scope");
  };

  const onRecurringPaymentClick = () => {
    setProduct(Product.RecurringPayment);
    addHeader(AxiosHeader.PaymentService, PaymentService.Recurring);
    setSelectedPaymentService(PaymentService.Recurring);
    goToAccounts();
  };

  // used by all usecases that are payments
  const goToAccounts = () => {
    // här vill vi se om det finns tidigare använda konton.
    if (!!accountsInStorage) {
      if (!accountsInStorage[0].debtorAccount) {
        // for backward compability
        setAccountsInStorage(null);
        setBankNameInStorage(null);
        setBankIdInStorage(null);
        history.push("/donationinfo");
      } else {
        setBankID(bankIdInStorage);
        setBankName(bankNameInStorage);
        if (!!pnrForAccountInStorage) {
          setPnr(pnrForAccountInStorage);
        }
        history.push("/accounts");
      }
    } else {
      history.push("/donationinfo");
    }
  };

  const onInvoiceClick = () => {
    setProduct(Product.Invoice);
    history.push("/donationinfo");
  };

  return (
    <div className="LandingPage">
      <img src="OP-logotype-inverted.svg" alt="Logotype" width={'400px'} />
      <img
        src="ope_logo_blue.png"
        className="LandingPageOpeLogoSmall"
        alt="ope"
      />
      <h1 className="LandingPageHeader">
        Try our demo and experience Open Banking for business.
      </h1>
      <FlexContainer
        flexDirection="column"
        alignItems="center"
        className="LandingPageSubtextWrapper"
      >
        <span className="LandingPageText">
          In this demo we show you how the basic and simple, unfolded version of
          our Open Payment's solution may perform.
        </span>
        <span className="LandingPageText">
          To start building immediately,{" "}
          <a
            href="https://developer.openpayments.io/auth"
            target="_blank"
            rel="noopener noreferrer"
            className="signup-link"
          >
            sign up here.
          </a>
        </span>
      </FlexContainer>

      <FlexContainer className="UseCaseList" justifyContent="space-around">
        <UseCase
          header="Payments"
          text="Try out our simple to use but powerful payment feature and discover how easy it is to make payments."
          icon="payments_icon.png"
          iconSmall="payments_icon_small.png"
          docsLink="https://docs.openpayments.io/docs/quickstart_pis"
          onActionClick={onPaymentClick}
        />
        <UseCase
          header="Account information"
          text="The AIS feature will give you an idea of what information you might get. When adding your brain to this data you will excel."
          icon="ais_icon.png"
          iconSmall="ais_icon_small.png"
          docsLink="https://docs.openpayments.io/docs/list_accounts"
          onActionClick={onAccountInformationClick}
        />
        <UseCase
          header="Account verification"
          text="Simply create a UX that both improves your customer journey and improves the quality of data you have achieved all objectives."
          icon="account_verification_icon.png"
          iconSmall="acc_ver_icon_small.png"
          docsLink="https://docs.openpayments.io/docs/list_accounts"
          onActionClick={onAccountVerificationClick}
        />
        <UseCase
          header="Recurring payment"
          text="Recurring payments are created to match your business. Add flexible numbers of withdrawals or timebased transactions easily."
          icon="recurring_icon.png"
          iconSmall="recurring_icon_small.png"
          docsLink="https://docs.openpayments.io/docs/quickstart_pis"
          onActionClick={onRecurringPaymentClick}
        />
        <UseCase
          header="Invoice solutions"
          text="Elevate your invoice experience and meet the expectations of digital invoicing. Improve communication, reduce costs and optimize the invoice process. "
          icon="invoice.svg"
          iconSmall="invoice_small.svg"
          docsLink="https://docs.openpayments.io/docs/quickstart_pis"
          onActionClick={onInvoiceClick}
        />
      </FlexContainer>
    </div>
  );
};
