import "./Button.css";

interface Props {
  text: string;
  onClick: () => void;
  color?: string;
  disabled?: boolean;
}
export const Button: React.FC<Props> = ({
  text,
  onClick,
  color,
  disabled = false,
}) => (
  <button
    className={`Button ${color && color}`}
    onClick={onClick}
    disabled={disabled}
  >
    {text}
  </button>
);
