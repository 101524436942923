import { useHistory } from "react-router-dom";
import { HiUserGroup } from "react-icons/hi";
import { BsFillPersonFill } from "react-icons/bs";
import "./Scope.css";
import React from "react";
import { Header } from "../../components/Header/Header";
import { PageContainer } from "../../components/PageContainer/PageContainer";
import useStore, { DemoStore } from "../../hooks/useStore";
import { Scopes } from "../../utils/enums";

export const Scope = () => {
  const history = useHistory();
  const setScope = useStore((store: DemoStore) => store.setScope);

  const onScopeClick = (scope) => {
    setScope(scope);
    if (scope === Scopes.Corporate) {
      history.push("/companyid");
    } else {
      history.push("/banks");
    }
  };

  return (
    <>
      <Header onBackClick={() => history.push("/")} />
      <PageContainer>
        <h2 className="AccountTypeHeader">Choose account type</h2>
        <Choice name="Private" onClick={() => onScopeClick(Scopes.Private)} />
        <Choice
          name="Corporate"
          onClick={() => onScopeClick(Scopes.Corporate)}
        />
      </PageContainer>
    </>
  );
};

const Choice = ({ name, onClick }) => (
  <div className="Choice" onClick={onClick}>
    {getIcon(name)}
    <span>{name}</span>
  </div>
);
const getIcon = (name) => {
  if (name === "Private") {
    return <BsFillPersonFill size={"2em"} color="white" />;
  }
  if (name === "Corporate") {
    return <HiUserGroup size={"2em"} color="white" />;
  }
};
