import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { FlexContainer } from "../../components/FlexContainer/FlexContainer";
import { Button } from "../../components/Button/Button";
import useStore, { DemoStore } from "../../hooks/useStore";
import { Header } from "../../components/Header/Header";
import { PageContainer } from "../../components/PageContainer/PageContainer";
import { PageHeader } from "../../components/PageHeader/PageHeader";

export const CompanyId = () => {
  const history = useHistory();
  const [value, setValue] = useState<string>("");
  const setCompanyId = useStore((store: DemoStore) => store.setCompanyId);

  const onNextClick = () => {
    setCompanyId(value.replace("-", ""));
    history.push("banks");
  };

  return (
    <>
      <Header onBackClick={() => history.push("/scope")} />
      <PageContainer>
        <PageHeader text="Enter your organisation number" />
        <FlexContainer
          flexDirection="column"
          justifyContent="space-between"
          className="PnrContainer"
        >
          <input
            value={value}
            onChange={(e) => setValue(e.target.value)}
            autoFocus
            placeholder="Organisation number"
            className="PnrInput"
          />
          <FlexContainer justifyContent="center" alignItems="center">
            <Button
              text="Continue"
              onClick={onNextClick}
              disabled={!validPnr(value)}
            />
          </FlexContainer>
        </FlexContainer>
      </PageContainer>
    </>
  );
};

const validPnr = (value) => !isNaN(+value.replace("-", ""));
