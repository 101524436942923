import React from "react";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import "./ErrorMessage.css";
import { Header } from "../Header/Header";
import { PageContainer } from "../PageContainer/PageContainer";
import { Button } from "../Button/Button";
import useStore, { DemoStore } from "../../hooks/useStore";
import { PageHeader } from "../PageHeader/PageHeader";

interface Props {
  text?: string;
  headerText?: string;
}

export const ErrorMessage: React.FC<Props> = ({
  text,
  headerText = "Sorry, we couldn't move on with your request",
}) => {
  const bankID = useStore((store: DemoStore) => store.bankID);
  return (
    <>
      <Header hideBackButton />
      <PageContainer>
        {!!bankID && (
          <FlexContainer justifyContent="center" alignItems="center">
            <img src={`${bankID}.png`} width="80px" height="80px" />
          </FlexContainer>
        )}
        <PageHeader text={headerText} />
        {!!text && <p>{text}</p>}
        <br></br>
        <br></br>
        <Button
          text="Back to start"
          onClick={() => (window.location.href = "/")}
        />
      </PageContainer>
    </>
  );
};
