import create from "zustand";
import { persist } from "zustand/middleware";
import { Invoice } from "../types/Invoice";

export interface DemoStore {
  resetStore: () => void;
  headerHeader: string;
  setHeader: (val: string | null) => void;
  bankID?: string;
  setBankID: (val: string | null) => void;
  bankName?: string;
  setBankName: (val: string) => void;
  scaMethod?: string;
  setScaMethod: (val: string | null) => void;
  bankIdLink?: string;
  setBankIdLink: (val: string | null) => void;
  confirmPaymentBankIdLink?: string;
  setConfirmPaymentBankIdLink: (val: string | null) => void;
  showBankInHeader: boolean;
  setShowBankInHeader: (val: boolean) => void;
  backButtonType?: string;
  setBackButtonType: (val: string | null) => void;
  isLoading: boolean;
  setIsLoading: (val: boolean) => void;
  loaderText: string;
  setLoaderText: (val: string | null) => void;
  apiCalls: any[];
  addApiCall: (val: any) => void;
  showApiTerminal: boolean;
  toggleApiTerminal: () => void;
  openApiTerminal: () => void;
  closeApiTerminal: () => void;
  isApiTerminalPaused: boolean;
  accountsInStorage?: any[];
  setAccountsInStorage: (val: any[] | null) => void;
  bankIdInStorage?: string;
  setBankIdInStorage: (val: string | null) => void;
  bankNameInStorage?: string;
  setBankNameInStorage: (val: string | null) => void;
  toggleIsApiTerminalPaused: () => void;
  product?: string;
  setProduct: (val: string | null) => void;
  pnr?: string;
  setPnr: (val: string | null) => void;
  isFromChooseBank: boolean;
  setIsFromChooseBank: (val: boolean) => void;
  isFromChooseAccount: boolean;
  setIsFromChooseAccount: (val: boolean) => void;
  sparbankerna?: any[];
  setSparbankerna: (val: any[] | null) => void;
  selectedSparbank?: string;
  setSelectedSparbank: (val: string | null) => void;
  selectedPaymentService?: string;
  setSelectedPaymentService: (val: string | null) => void;
  pnrPrev?: string;
  setPnrPrev: (val: string | null) => void;
  pnrForAccountInStorage?: string;
  setPnrForAccountInStorage: (val: string | null) => void;
  scopeInStorage?: string;
  setScopeInStorage: (val: string) => void;
  invoiceAccount?: any;
  setInvoiceAccount: (val: any | null) => void;
  isChangingAccount: boolean;
  setIsChangingAccount: (val: boolean) => void;
  qrImage?: string;
  setQrImage: (val: string | null) => void;
  invoiceID?: string;
  setInvoiceID: (val: string) => void;
  invoiceDate?: string;
  setInvoiceDate: (val: string) => void;
  invoice?: Invoice;
  setInvoice: (invoice: Invoice) => void;
  scope?: string;
  setScope: (scope: string) => void;
  companyId?: string;
  setCompanyId: (setCompanyId: string) => void;
}

const useStore = create(
  persist(
    (set) => ({
      resetStore: () =>
        set(() => ({
          apiCalls: [],
          backButtonType: null,
          bankID: null,
          bankIdLink: null,
          bankName: null,
          companyId: null,
          confirmPaymentBankIdLink: null,
          headerHeader: "",
          invoice: null,
          invoiceID: null,
          isApiTerminalPaused: false,
          isChangingAccount: false,
          isFromChooseAccount: false,
          isFromChooseBank: false,
          isLoading: false,
          loaderText: "",
          paymentService: null,
          pnr: null,
          product: null,
          qrImage: null,
          scaMethod: null,
          selectedPaymentService: null,
          selectedSparbank: null,
          showBankInHeader: false,
          sparbankerna: null,
          scope: null,
        })),
      headerHeader: "",
      setHeader: (value: string) => set(() => ({ headerHeader: value })),
      bankID: null,
      setBankID: (value: string) => set(() => ({ bankID: value })),
      bankName: null,
      setBankName: (value: string) => set(() => ({ bankName: value })),
      showBankInHeader: false,
      setShowBankInHeader: (value: boolean) =>
        set(() => ({ showBankInHeader: value })),
      backButtonType: null,
      setBackButtonType: (value: string) =>
        set(() => ({ backButtonType: value })),
      isLoading: false,
      setIsLoading: (value: boolean) => set(() => ({ isLoading: value })),
      loaderText: "",
      setLoaderText: (value: string) => set(() => ({ loaderText: value })),
      apiCalls: [],
      addApiCall: (apiCall: any) =>
        set((state: DemoStore) =>
          state.isApiTerminalPaused
            ? state.apiCalls
            : {
                apiCalls: [...state.apiCalls, apiCall],
              }
        ),
      showApiTerminal: false,
      toggleApiTerminal: () =>
        set((state: DemoStore) => ({
          showApiTerminal: !state.showApiTerminal,
        })),
      openApiTerminal: () =>
        set(() => ({
          showApiTerminal: true,
        })),
      closeApiTerminal: () =>
        set(() => ({
          showApiTerminal: false,
        })),
      isApiTerminalPaused: false,
      toggleIsApiTerminalPaused: () =>
        set((state: DemoStore) => ({
          isApiTerminalPaused: !state.isApiTerminalPaused,
        })),
      scaMethod: null,
      setScaMethod: (scaMethod: string | null) => set(() => ({ scaMethod })),
      bankIdLink: null,
      setBankIdLink: (bankIdLink: string | null) => set(() => ({ bankIdLink })),
      accountsInStorage: null,
      setAccountsInStorage: (accountsInStorage: any[] | null) =>
        set(() => ({ accountsInStorage })),
      bankIdInStorage: null,
      setBankIdInStorage: (bankIdInStorage: string | null) =>
        set(() => ({ bankIdInStorage })),
      bankNameInStorage: null,
      setBankNameInStorage: (bankNameInStorage: string | null) =>
        set(() => ({ bankNameInStorage })),
      confirmPaymentBankIdLink: null,
      setConfirmPaymentBankIdLink: (confirmPaymentBankIdLink: string | null) =>
        set(() => ({ confirmPaymentBankIdLink })),
      product: null,
      setProduct: (product: string | null) => set(() => ({ product })),
      pnr: null,
      setPnr: (pnr: string | null) => set(() => ({ pnr })),
      isFromChooseBank: false,
      setIsFromChooseBank: (isFromChooseBank: boolean) =>
        set(() => ({ isFromChooseBank })),
      isFromChooseAccount: false,
      setIsFromChooseAccount: (isFromChooseAccount: boolean) =>
        set(() => ({ isFromChooseAccount })),
      sparbankerna: null,
      setSparbankerna: (sparbankerna: any[] | null) =>
        set(() => ({ sparbankerna })),
      selectedSparbank: null,
      setSelectedSparbank: (selectedSparbank: string | null) =>
        set(() => ({ selectedSparbank })),
      selectedPaymentService: null,
      setSelectedPaymentService: (selectedPaymentService: string | null) =>
        set(() => ({ selectedPaymentService })),
      pnrPrev: null,
      setPnrPrev: (pnrPrev: string | null) => set(() => ({ pnrPrev })),
      pnrForAccountInStorage: null,
      setPnrForAccountInStorage: (pnrForAccountInStorage: string | null) =>
        set(() => ({ pnrForAccountInStorage })),
      invoiceAccount: null,
      setInvoiceAccount: (invoiceAccount: any | null) =>
        set(() => ({ invoiceAccount })),
      isChangingAccount: false,
      setIsChangingAccount: (isChangingAccount: boolean) =>
        set(() => ({ isChangingAccount })),
      qrImage: null,
      setQrImage: (qrImage: string | null) => set(() => ({ qrImage })),
      invoiceID: null,
      setInvoiceID: (invoiceID: string) => set(() => ({ invoiceID })),
      invoiceDate: null,
      setInvoiceDate: (invoiceDate: string) => set(() => ({ invoiceDate })),
      invoice: null,
      setInvoice: (invoice: Invoice) => set(() => ({ invoice })),
      scope: null,
      setScope: (scope: string) => set(() => ({ scope })),
      companyId: null,
      setCompanyId: (companyId: string) => set(() => ({ companyId })),
      scopeInStorage: null,
      setScopeInStorage: (scopeInStorage: string) =>
        set(() => ({ scopeInStorage })),
    }),
    {
      name: "demo-storage",
      getStorage: () => localStorage,
    }
  )
);

export default useStore;
