import React, { useEffect, useState, useRef } from "react";
import QRCode from "qrcode.react";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { getPaymentStatus } from "../../api/endpoints";
import { FlexContainer } from "../../components/FlexContainer/FlexContainer";
import { ErrorMessage } from "../../components/ErrorMessage/ErrorMessage";
import { PageHeader } from "../../components/PageHeader/PageHeader";
import { PageContainer } from "../../components/PageContainer/PageContainer";
import { Header } from "../../components/Header/Header";
import { Spinner } from "../../components/Loader/Loader";
import useStore, { DemoStore } from "../../hooks/useStore";
import { getEndpointTimestamp } from "../../utils/functions";
import {
  BankId,
  Product,
  ReactQueryStatus,
  ScaStatus,
} from "../../utils/enums";
import "./PaymentQr.css";
import InfoBox from "../../components/InfoBox";
import { parseErrorMessage } from "../../utils/reactQuery";

export const PaymentQr = () => {
  const history = useHistory();
  const {
    confirmPaymentBankIdLink,
    addApiCall,
    product,
    qrImage,
    bankID,
    bankName,
  } = useStore((state: DemoStore) => state);
  const [qrImageLocal, setQrImageLocal] = useState(qrImage);
  const [firstStatus, setFirstStatus] = useState(null);

  const [secondBankIdLink, setSecondBankIdLink] = useState(null);

  const [isWaitingForSecondScan, setIsWaitingForSecondScan] = useState(false);
  const getPaymentStatusCountRef = useRef(0);

  const { data, isError, isFetching, status, error } = useQuery(
    "getPaymentStatus",
    () => getPaymentStatus(),
    {
      refetchInterval: 1000,
      cacheTime: 0,
    }
  );
  useEffect(() => setQrImageLocal(qrImage), [qrImage, setQrImageLocal]);

  useEffect(() => {
    if (
      !isFetching &&
      status === ReactQueryStatus.Success &&
      getPaymentStatusCountRef.current > 1
    ) {
      addApiCall({
        endpoint: "Get Payment Initiation Authorisation SCA Status",
        statusCode: "200",
        timestamp: getEndpointTimestamp(),
      });
    }
    if (isFetching) {
      getPaymentStatusCountRef.current++;
    }
  }, [isFetching, addApiCall, status]);

  useEffect(() => {
    // Special case for Swedbank
    if (!!data && !!data.scaStatus && !firstStatus) {
      setFirstStatus(data.scaStatus);
    }

    // Special case for Nordea
    if (
      !!data &&
      !!data.bankidLink &&
      data.bankidLink !== confirmPaymentBankIdLink
    ) {
      setSecondBankIdLink(data.bankidLink);
    }

    if (bankID === BankId.NDEASESS && !!data && !!data.bankidLink)
      if (!!data && !!data.qrImage) {
        setQrImageLocal(data.qrImage);
      }
    if (!!data && data.scaStatus === ScaStatus.Finalised) {
      if (!isFetching && status === ReactQueryStatus.Success) {
        addApiCall({
          endpoint: "Get Payment Initiation Status",
          statusCode: "200",
          timestamp: getEndpointTimestamp(),
        });
      }
      if (product === Product.Invoice) {
        history.push("/invoicesuccess");
      } else {
        history.push("/success");
      }
    }

    if (!!data && data.scaStatus === ScaStatus.PsuAuthenticated) {
      setIsWaitingForSecondScan(true);
    }
  }, [data, history, addApiCall, isFetching, status]);

  if (isError) {
    return <ErrorMessage text={parseErrorMessage(error)} />;
  }

  if (isWaitingForSecondScan) {
    return (
      <Spinner text="Please keep your BankID app open, you will soon be asked to sign again" />
    );
  }

  if (!firstStatus) {
    return <Spinner text="Generating QR code" />;
  }

  return (
    <>
      <Header showBankInHeader onBackClick={() => history.push("/accounts")} />
      <PageContainer>
        <FlexContainer flexDirection="column" fullHeight>
          <FlexContainer flexDirection="column" justifyContent="flex-start">
            <PageHeader text="Open the BankID app" />
            <FlexContainer margin="16px 0">
              <span>
                Open the Mobile BankID app and scan this QR code to
                authenticate.
              </span>
            </FlexContainer>
          </FlexContainer>
          {isTwoScansRequired(bankID, firstStatus) && (
            <FlexContainer flexDirection="column" alignItems="center">
              <InfoBox
                text={`Please note that ${bankName} requires two authentications.`}
              />
              <span className="AuthenticationNumber">{`Authentication ${
                isSecondScan(
                  bankID,
                  data,
                  confirmPaymentBankIdLink,
                  secondBankIdLink
                )
                  ? "2"
                  : "1"
              } of 2`}</span>
            </FlexContainer>
          )}

          <FlexContainer
            fullWidth
            fullHeight
            justifyContent="center"
            alignItems="center"
          >
            {qrImageLocal ? (
              <img src={qrImageLocal} height="180px" width="180px" />
            ) : (
              <QRCode
                value={
                  !secondBankIdLink
                    ? confirmPaymentBankIdLink
                    : secondBankIdLink
                }
                height={180}
                width={180}
              />
            )}
          </FlexContainer>
        </FlexContainer>
      </PageContainer>
    </>
  );
};

const isTwoScansRequired = (bankId: string, firstStatus?: ScaStatus) =>
  (bankId === BankId.SWEDSESS && firstStatus !== ScaStatus.Started) ||
  bankId === BankId.NDEASESS;

const isSecondScan = (
  bankID: string,
  data: any,
  firstBankIdLink,
  secondBankIdLink
) => {
  switch (bankID) {
    case BankId.SWEDSESS:
      return (
        !!data &&
        (data.scaStatus === ScaStatus.AuthoriseCreditorAccountStarted ||
          data.scaStatus === ScaStatus.Started)
      );
    case BankId.NDEASESS:
      return !!secondBankIdLink && firstBankIdLink !== secondBankIdLink;
    default:
      return false;
  }
};
