import React from "react";
import "./PageContainer.css";

export const PageContainer = ({
  children,
  paddingTop = "24px",
  paddingRight = "24px",
  paddingBottom = "24px",
  paddingLeft = "24px",
  className = "",
}) => (
  <div
    style={{
      paddingTop,
      paddingRight,
      paddingBottom,
      paddingLeft,
    }}
    className={`PageContainer${!!className ? ` ${className}` : ""}`}
  >
    {children}
  </div>
);
