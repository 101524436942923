import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { FlexContainer } from "../../components/FlexContainer/FlexContainer";
import { Spinner } from "../../components/Loader/Loader";
import { getAspsps } from "../../api/endpoints";
import { useQuery } from "react-query";
import { ErrorMessage } from "../../components/ErrorMessage/ErrorMessage";
import { useTransition, animated } from "react-spring";
import { PageContainer } from "../../components/PageContainer/PageContainer";
import { PageHeader } from "../../components/PageHeader/PageHeader";
import "./Banks.css";
import useStore, { DemoStore } from "../../hooks/useStore";
import { Header } from "../../components/Header/Header";
import {
  BankId,
  PaymentService,
  Product,
  ReactQueryStatus,
} from "../../utils/enums";
import {
  getEndpointTimestamp,
  isMobile,
  isTestEnvironment,
} from "../../utils/functions";
import { ExtendedBankIdInfo } from "../ExtendedBankIdInfo/ExtendedBankIdInfo";

export const Banks = ({
  onBankClick,
  createConsentStatus,
  loadingConsentText,
  isRedirect,
  redirectUri,
}) => {
  const history = useHistory();

  const [showExtendedBankIdInfo, setShowExtendedBankIdInfo] = useState(false);

  const addApiCall = useStore((state: DemoStore) => state.addApiCall);
  const setBankID = useStore((state: DemoStore) => state.setBankID);
  const setBankName = useStore((state: DemoStore) => state.setBankName);
  const bankID = useStore((state: DemoStore) => state.bankID);
  const bankName = useStore((state: DemoStore) => state.bankName);
  const product = useStore((state: DemoStore) => state.product);
  const selectedPaymentService = useStore(
    (state: DemoStore) => state.selectedPaymentService
  );
  const setIsFromChooseBank = useStore(
    (state: DemoStore) => state.setIsFromChooseBank
  );
  const setSparbankerna = useStore((state: DemoStore) => state.setSparbankerna);

  useEffect(() => setIsFromChooseBank(true), []);

  const {
    data: aspsps,
    isLoading: isFetchingAspsps,
    isError,
  } = useQuery("getAspsps", () => getAspsps());

  useEffect(() => {
    addApiCall({
      endpoint: "Request ASPSP Information token",
      statusCode: "200",
      timestamp: getEndpointTimestamp(),
    });
  }, [addApiCall]);

  useEffect(() => {
    if (aspsps) {
      addApiCall({
        endpoint: "Get ASPSP List",
        statusCode: "200",
        timestamp: getEndpointTimestamp(),
      });
    }
  }, [aspsps, addApiCall]);

  const _aspsps = !aspsps
    ? []
    : isTestEnvironment()
    ? [...aspsps, { id: "8", name: "Länsförsäkringar Bank" }]
    : aspsps;

  const transitions = useTransition(
    _aspsps
      .filter((bank) => {
        if (
          // periodic payments only supported for SEB and Swedbank
          selectedPaymentService === PaymentService.Recurring &&
          bank.id !== BankId.ESSESESS &&
          bank.id !== BankId.SWEDSESS
        ) {
          return false;
        }
        if (product === Product.AccountVerification) {
          return (
            bank.id === BankId.HANDSESS ||
            bank.id === BankId.ESSESESS ||
            bank.id === BankId.SWEDSESS
          );
        } else if (product === Product.RecurringPayment) {
          return bank.id !== BankId.HANDSESS;
        }
        return true;
      })
      .sort((a, b) => (a.name < b.name ? -1 : 1)),
    (item) => item.id,
    {
      from: { opacity: 0 },
      enter: { opacity: 1 },
      trail: 75,
    }
  );

  useEffect(() => {
    if (createConsentStatus === ReactQueryStatus.Success) {
      if (isRedirect) {
        window.location.href = redirectUri;
      } else {
        history.push(isMobile() ? "/consentopenbankid" : "/qr");
      }
    }
  }, [createConsentStatus, history, redirectUri, isRedirect]);

  useEffect(() => {
    if (aspsps) {
      const sparbanken = aspsps.find((item) => item.id === BankId.SPARBANKERNA);
      if (sparbanken) {
        setSparbankerna(sparbanken.subBanks);
      }
    }
  }, [aspsps, setSparbankerna]);

  const bankClick = (id, name) => {
    if (
      [BankId.SWEDSESS, BankId.SPARBANKERNA].includes(id) &&
      (product === Product.PaymentInitiation ||
        product === Product.RecurringPayment ||
        product === Product.Invoice)
    ) {
      // Visa extended bankid-info
      setBankID(id);
      setBankName(name);
      setShowExtendedBankIdInfo(true);
      return;
    }

    if (id === BankId.SPARBANKERNA) {
      history.push("/choosesparbank");
    } else {
      onBankClick(id, name);
    }
  };

  const afterCheckingExtendedBankIdBox = () => {
    if (bankID === BankId.SPARBANKERNA) {
      history.push("/choosesparbank");
    } else {
      onBankClick(bankID, bankName);
    }
  };

  if (isFetchingAspsps) {
    return <Spinner text="Loading banks" />;
  }

  if (
    createConsentStatus === ReactQueryStatus.Loading ||
    (createConsentStatus === ReactQueryStatus.Success && isRedirect)
  ) {
    return <Spinner text={loadingConsentText} />;
  }

  if (showExtendedBankIdInfo) {
    return <ExtendedBankIdInfo onNextClick={afterCheckingExtendedBankIdBox} />;
  }

  if (!aspsps || isError) {
    return <ErrorMessage />;
  }

  return (
    <>
      <Header
        isInvoice={product === Product.Invoice}
        showCompanyName={product === Product.Invoice}
        onBackClick={() => history.push("/")}
      />
      <PageContainer paddingRight="0">
        <PageHeader text="Choose your bank" />
        <div className="ChooseBankList">
          {transitions.map(({ item, props, key }) => (
            <animated.div key={key} style={props}>
              <Bank bank={item} onClick={() => bankClick(item.id, item.name)} />
            </animated.div>
          ))}
        </div>
        <TermsAndConditions />
      </PageContainer>
    </>
  );
};

const Bank = ({ bank, onClick }) => (
  <FlexContainer
    alignItems="center"
    onClick={onClick}
    margin="20px 0 20px 0px"
    cursor="pointer"
    className="Bank"
  >
    <img src={`${bank.id}.png`} alt="bank" className="BankImage" />
    <span className="BankName">{bank.name}</span>
  </FlexContainer>
);

const listItems = [
  "Kontodetaljer (kontohavare och kontonummer)",
  "Kontobalans",
  "Transaktioner (senaste 25 månaderna)",
];
const TermsAndConditions = () => {
  const [expanded, setExpanded] = useState(false);

  return (
    <FlexContainer flexDirection="column" className="TermsAndConditions">
      <span className="TermsAndConditionsText">
        Jag godkänner att Open Payments får tillgång till mitt betalkonto. Open
        Payments kommer att ta del av finansiell information från ditt konto i 1
        dag.
      </span>
      {!expanded && (
        <span
          onClick={() => setExpanded(true)}
          className="TermsAndConditionsShowMore"
        >
          Show more
        </span>
      )}
      {expanded && (
        <ul className="TermsAndConditionsList">
          {listItems.map((item) => (
            <li key={item} className="TermsAndConditionsListItem">
              {item}
            </li>
          ))}
        </ul>
      )}

      <a
        className="TermsAndConditionsLink"
        href="about:blank"
        target="_blank"
        rel="noreferrer"
      >
        Klicka här för att läsa vår integrationspolicy
      </a>
    </FlexContainer>
  );
};
