import { useQuery } from "react-query";
import { getAccounts } from "../../api/endpoints";
import { FlexContainer } from "../../components/FlexContainer/FlexContainer";
import { Button } from "../../components/Button/Button";
import { Spinner } from "../../components/Loader/Loader";
import "../Accounts/Accounts.css";
import { useEffect, useState } from "react";
import { ErrorMessage } from "../../components/ErrorMessage/ErrorMessage";
import { PageContainer } from "../../components/PageContainer/PageContainer";
import { PageHeader } from "../../components/PageHeader/PageHeader";
import { Header } from "../../components/Header/Header";
import { useHistory } from "react-router-dom";
import useStore, { DemoStore } from "../../hooks/useStore";
import { getEndpointTimestamp } from "../../utils/functions";

export const AccountVerification = () => {
  const [selectedAccount, setSelectedAccount] = useState("");
  const { setIsFromChooseAccount, addApiCall } = useStore(
    (state: DemoStore) => state
  );

  useEffect(() => setIsFromChooseAccount(true), []);

  const history = useHistory();

  const {
    data: accounts,
    isFetching: isLoadingAccounts,
    refetch: fetchAccounts,
    isError: isFetchAccountsError,
  } = useQuery("getAccounts", () => getAccounts(), { enabled: false });

  useEffect(() => {
    fetchAccounts();
  }, [fetchAccounts]);

  useEffect(() => {
    if (accounts) {
      addApiCall({
        endpoint: "Get Account List",
        timestamp: getEndpointTimestamp(),
        statusCode: "200",
      });
      setSelectedAccount(accounts[0].id);
    }
  }, [accounts, addApiCall]);

  useEffect(() => {
    if (isFetchAccountsError) {
      history.push("/accountverificationfailed");
    }
  }, [isFetchAccountsError, history]);

  const verify = () => history.push("/verified");

  if (isLoadingAccounts) {
    return <Spinner text="Loading accounts" />;
  }

  if (!accounts) {
    return <ErrorMessage />;
  }

  return (
    <>
      <Header headline="Payment initiation" />
      <PageContainer>
        <PageHeader text="Select account you wish to verify" />
        <FlexContainer flexDirection="column" justifyContent="space-between">
          <div className="AccountList">
            {accounts.map((account) => (
              <Account
                key={account.id}
                account={account}
                selected={selectedAccount === account.id}
                onClick={() => setSelectedAccount(account.id)}
              />
            ))}
          </div>
          <FlexContainer margin="14px 0 0 0" fullWidth justifyContent="center">
            <Button onClick={verify} text="Verify account" />
          </FlexContainer>
        </FlexContainer>
      </PageContainer>
    </>
  );
};

const Account = ({ account, selected, onClick }) => (
  <FlexContainer
    alignItems="center"
    onClick={onClick}
    className={`Account ${selected && " AccountSelected"}`}
  >
    <FlexContainer fullWidth flexDirection="column">
      <span
        className={`AccountName ${
          selected ? " AccountSelectedFont" : "AccountNonSelectedFont"
        }`}
      >
        {account.name}
      </span>
      <FlexContainer
        fullWidth
        justifyContent="space-between"
        margin="14px 0 0 0"
      >
        <span
          className={`AccountNumber ${
            selected ? " AccountSelectedFont" : "AccountNonSelectedFont"
          }`}
        >
          {account.bban}
        </span>
      </FlexContainer>
    </FlexContainer>
  </FlexContainer>
);
