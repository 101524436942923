import { PageContainer } from "../../components/PageContainer/PageContainer";
import React from "react";
import { FlexContainer } from "../../components/FlexContainer/FlexContainer";
import { Header } from "../../components/Header/Header";

export const Verified = () => (
  <>
    <Header />
    <PageContainer>
      <FlexContainer
        fullWidth
        fullHeight
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <img
          style={{
            height: 80,
            width: "auto",
          }}
          alt="success"
          src={`checkmark.svg`}
        />
        <h1>Account verified</h1>
      </FlexContainer>
    </PageContainer>
  </>
);
