import React from "react";
import { useTransition, config, animated } from "react-spring";
import { Endpoint } from "./ApiCall/Endpoint";
import { StatusCode } from "./ApiCall/StatusCode";
import { Timestamp } from "./ApiCall/Timestamp";
import "./ApiCall/ApiCall.css";
export const MultipleApiCalls = ({ endpoints }) => {
  const transitions = useTransition(endpoints, (item, i) => i, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    config: config.stiff,
    trail: 150,
  });
  return (
    <>
      {transitions.map(({ item, props, key }) => (
        <animated.div key={key} className="ApiCall" style={props}>
          <Timestamp time={item.timestamp} />
          <StatusCode code={item.statusCode} isLoading={item.isLoading} />
          <Endpoint endpoint={item.endpoint} />
        </animated.div>
      ))}
    </>
  );
};
