import React from "react";
import "./InfoBox.css";

interface Props {
  text: string;
}

export const InfoBox: React.FC<Props> = ({ text }) => (
  <div className="InfoBox">
    <img src="info_icon.png" className="InfoBoxIcon" />
    <span className="InfoBoxText">{text}</span>
  </div>
);
