import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { FlexContainer } from "../../../components/FlexContainer/FlexContainer";
import { PageContainer } from "../../../components/PageContainer/PageContainer";
import { ErrorMessage } from "../../../components/ErrorMessage/ErrorMessage";
import useStore, { DemoStore } from "../../../hooks/useStore";
import { Field } from "./Field/Field";
import {
  bicToId,
  dateIsWeekday,
  getNextWeekday,
  isAndroid,
  isMobile,
  dateAsText,
  addMonths,
} from "../../../utils/functions";
import "./InvoiceLanding.css";
import {
  AxiosHeader,
  InvoiceStatusEnum,
  Product,
  ScaMethod,
  Scopes,
} from "../../../utils/enums";
import { useMutation } from "react-query";
import {
  createInvoice,
  getInvoice,
  initiateInvoicePayment,
  updateInvoice,
} from "../../../api/endpoints";
import { Spinner } from "../../../components/Loader/Loader";
import { Header } from "../../../components/Header/Header";
import { Status } from "./Status/Status";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { addHeader } from "../../../utils/axios";

export const InvoiceLanding = () => {
  const {
    invoiceAccount,
    setProduct,
    setSelectedPaymentService,
    setConfirmPaymentBankIdLink,
    setIsFromChooseAccount,
    setIsChangingAccount,
    setBankID,
    invoiceID,
    setInvoiceID,
    setInvoice: setInvoiceInStorage,
    setInvoiceDate,
    setScope,
    setCompanyId,
  } = useStore((state: DemoStore) => state);

  const history = useHistory();

  const [invoice, setInvoice] = useState(null);
  const [_invoiceID, _setInvoiceID] = useState(null);
  const [isFetchingInvoice, setIsFetchingInvoice] = useState(true);
  const [paymentDate, setPaymentDate] = useState(getNextWeekday());

  const {
    mutate: _createInvoice,
    data: createdInvoice,
    isLoading: isCreatingInvoice,
  } = useMutation((data: any) => createInvoice(data));

  useEffect(() => {
    const params = new URLSearchParams(window.location.search); // id=123
    let id = params.get("id"); // 123

    let threeMonthsForward = addMonths(new Date(), 3)
      .toISOString()
      .split("T")[0];

    if (!!id) {
      _setInvoiceID(id);
    } else if (!!invoiceID) {
      _setInvoiceID(invoiceID);
    } else {
      _createInvoice({
        amount: "1",
        paymentType: "payments",
        expiryDate: threeMonthsForward,
        message: "This is a demo invoice for testing",
      });
    }
  }, []);

  useEffect(() => {
    async function _fetchInvoice(id) {
      if (!isFetchingInvoice) {
        setIsFetchingInvoice(true);
      }
      const result = await getInvoice(id);
      setInvoice(result);
      setInvoiceInStorage(result);
      setIsFetchingInvoice(false);
      setSelectedPaymentService(result.paymentType.id);
    }
    if (_invoiceID) {
      _fetchInvoice(_invoiceID);
    }
  }, [_invoiceID]);

  const {
    mutate: createPayment,
    isLoading: isCreatingPayment,
    data: createPaymentData,
    isSuccess: isCreatePaymentSuccess,
    reset: resetmutation,
  } = useMutation((body: any) => initiateInvoicePayment(body));

  const { mutate: _updateInvoice } = useMutation((body: any) =>
    updateInvoice(body)
  );

  useEffect(() => {
    setProduct(Product.Invoice);
    setIsFromChooseAccount(true);
    setIsChangingAccount(false);
    setScope(Scopes.Private);
    setCompanyId(null);
  }, []);

  useEffect(() => {
    if (invoice && invoice.invoiceStatus === InvoiceStatusEnum.Created) {
      _updateInvoice({ ...invoice, invoiceStatus: InvoiceStatusEnum.Received });
    }
  }, [invoice]);

  useEffect(() => {
    if (createdInvoice) {
      _setInvoiceID(createdInvoice.id);
    }
  }, [createdInvoice]);

  useEffect(() => {
    if (invoiceAccount) {
      setBankID(bicToId(invoiceAccount.bic));
    }
  }, [invoiceAccount]);

  const onPayClick = () => {
    setInvoiceDate(paymentDate.toISOString().split("T")[0]);
    setInvoiceID(_invoiceID);
    if (invoiceAccount) {
      setBankID(bicToId(invoiceAccount.bic));
      addHeader(AxiosHeader.BankId, bicToId(invoiceAccount.bic));
      // gå direkt till att skapa betalning
      createPayment({
        invoiceID: _invoiceID,
        debtorAccount: invoiceAccount.debtorAccount,
        paymentDate: paymentDate.toISOString().split("T")[0],
        isMobile: isMobile(),
      });
    } else {
      // Gå till välj bank osv
      history.push("/banks");
    }
  };

  const onChangeBankClick = () => {
    setIsChangingAccount(true);
    history.push("/banks");
  };

  useEffect(() => {
    if (isCreatePaymentSuccess) {
      let { qrCode, scaOAuth, scaMethod } = createPaymentData;

      if (scaMethod === ScaMethod.Decoupled) {
        if (isAndroid()) {
          qrCode = qrCode.replace("bankid:///", "https://app.bankid.com/");
        }
        setConfirmPaymentBankIdLink(qrCode);
        resetmutation();
        if (isMobile()) {
          history.push("/paymentopenbankid");
        } else {
          history.push("/confirmpayment");
        }
      } else if (scaMethod === ScaMethod.OAuthRedirect) {
        window.location.href = scaOAuth;
      }
    }
  }, [isCreatePaymentSuccess]);

  if (isCreatingPayment) {
    return <Spinner text={"Initiating payment"} />;
  }

  if (isFetchingInvoice || isCreatingInvoice) {
    return <Spinner text={"Loading invoice"} />;
  }

  if (!invoice) {
    return <ErrorMessage text="Invoice not found" />;
  }

  const getAmountText = (invoice) =>
    isRecurring(invoice)
      ? `SEK ${invoice.amount}.00 / month`
      : `SEK ${invoice.amount}.00`;

  const isRecurring = (invoice) =>
    invoice.paymentType.id === "periodic-payments";

  return (
    <>
      <Header showCompanyName showInvoiceText isInvoice />
      <PageContainer className="InvoicePageContainer">
        <FlexContainer
          flexDirection="column"
          justifyContent="space-between"
          fullHeight
        >
          <FlexContainer flexDirection="column">
            <FlexContainer justifyContent="space-between" alignItems="flex-end">
              <Field name="Amount" value={getAmountText(invoice)} />
              <Status status="Unpaid" />
            </FlexContainer>
            {isRecurring(invoice) && (
              <Field
                name="First payment"
                value={dateAsText(invoice.recurringStartDate)}
              />
            )}
            <Field name="Receiver" value="Företaget AB" />
            {invoice.message && (
              <Field name="Message" value={invoice.message} />
            )}
            <div className="InvoiceLine"></div>

            {invoiceAccount && (
              <FlexContainer flexDirection="column">
                <span className="InvoiceBankField">Från konto</span>
                <FlexContainer alignItems="center">
                  <img
                    src={`${bicToId(invoiceAccount.bic)}.png`}
                    alt="bank"
                    className="BankImage"
                  />
                  {invoiceAccount.name}
                  <img
                    src={"change_account.svg"}
                    alt="change_account"
                    height="20px"
                    style={{ marginLeft: 10 }}
                    onClick={onChangeBankClick}
                  />
                </FlexContainer>
              </FlexContainer>
            )}
            {!isRecurring(invoice) && (
              <Field
                name="Payment date"
                value="18 oktober"
                icon="calendar_icon.svg"
                component={
                  <DatePicker
                    selected={paymentDate}
                    onChange={setPaymentDate}
                    filterDate={dateIsWeekday}
                    minDate={getNextWeekday()}
                    placeholderText="Select payment date"
                  />
                }
              />
            )}
          </FlexContainer>
          <FlexContainer flexDirection="column" alignItems="center">
            <button className="InvoicePayButton" onClick={onPayClick}>
              {isRecurring(invoice) ? "Initiate payment" : "Pay"}
            </button>

            <FlexContainer className="PoweredByContainer">
              <span className="PoweredBy">Powered by</span>
              <img src="opelogo.png" height="20px" />
            </FlexContainer>
          </FlexContainer>
        </FlexContainer>
      </PageContainer>
    </>
  );
};
