import { Invoice } from "./../types/Invoice";
import { GenerateConsentDTO } from "./../../../server/src/types/GenerateConsentDTO";
import axios from "axios";
import { GetConsentAuthorisationSCAStatusDTO } from "../types/GetConsentAuthorisationSCAStatusDTO";
import { ASPSPDTO } from "../types/ASPSPDTO";
import { AccountDTO } from "../types/AccountDTO";
// import data from "./appsettings.json";

axios.defaults.headers.get["Content-Type"] = "application/json";
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.withCredentials = true;

// aspsp
export const getAspsps = async (): Promise<ASPSPDTO[]> =>
  (await axios.get(`${(window as any).config}aspsp`)).data;

// consent
export const generateConsent = async (body: any): Promise<GenerateConsentDTO> =>
  (await axios.post(`${(window as any).config}consent`, body)).data;

export const getConsentAuthorisationSCAStatus = async (): Promise<GetConsentAuthorisationSCAStatusDTO> =>
  (await axios.get(`${(window as any).config}consent/scastatus`)).data;

export const finaliseConsentAfterRedirect = async (body: any) =>
  await axios.post(`${(window as any).config}consent/finalise`, body);

export const deleteConsent = async () =>
  await axios.delete(`${(window as any).config}consent`);

// ais
export const getAccounts = async (): Promise<AccountDTO[]> =>
  (await axios.get(`${(window as any).config}ais/accounts`)).data;

export const getAccountInformation = async () =>
  (await axios.get(`${(window as any).config}ais/accountinformation`)).data;

// pis
export const initiatePayment = async (body: any) =>
  (await axios.post(`${(window as any).config}pis/payment`, body)).data;

export const getPaymentStatus = async () =>
  (await axios.get(`${(window as any).config}pis/paymentscastatus`)).data;

export const finalizePaymentAfterRedirect = async (body: any) =>
  await axios.post(
    `${(window as any).config}pis/finalisePaymentAfterRedirect`,
    body
  );

// invoice
export const createInvoice = async (body: any) =>
  (await axios.post(`${(window as any).config}invoice`, body)).data;

export const getInvoice = async (id: string): Promise<Invoice> =>
  (await axios.get(`${(window as any).config}invoice/${id}`)).data;

// export const getInvoiceAccounts = async (): Promise<any[]> =>
//   (await axios.get(`${(window as any).config}invoice/accounts`)).data;

export const initiateInvoicePayment = async (body: any) =>
  (await axios.post(`${(window as any).config}invoice/payment`, body)).data;

export const updateInvoice = async (data: any) =>
  await axios.put(`${(window as any).config}invoice`, data);
