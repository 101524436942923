import React, { ReactElement } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import axios from "axios";
import { QueryClient, QueryClientProvider } from "react-query";
import "semantic-ui-css/semantic.min.css";
import "./index.css";
const queryClient = new QueryClient();

axios
  .get("appsettings.json")
  .then((response) => {
    (window as any).config = response.data.endpoint;
    return (
      <React.StrictMode>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </React.StrictMode>
    );
  })
  .catch((e) => {
    return (
      <p style={{ color: "red", textAlign: "center" }}>
        Error while fetching global config
      </p>
    );
  })
  .then((reactElement: ReactElement) => {
    ReactDOM.render(reactElement, document.getElementById("root"));
  });
