import React, { useEffect, useState } from "react";
import QRCode from "qrcode.react";
import { useQuery } from "react-query";
import { getInvoice } from "../../../api/endpoints";
import { Spinner } from "../../../components/Loader/Loader";
import { useHistory } from "react-router-dom";
import { Header } from "../../../components/Header/Header";
import { PageContainer } from "../../../components/PageContainer/PageContainer";
import { PageHeader } from "../../../components/PageHeader/PageHeader";
import { FlexContainer } from "../../../components/FlexContainer/FlexContainer";
import "./InvoiceQR.css";
import { InvoiceStatusEnum } from "../../../utils/enums";

export const InvoiceQR = () => {
  const params = new URLSearchParams(window.location.search);
  let id = params.get("id");
  const history = useHistory();
  const [qr, setQr] = useState("");
  const { data: invoice } = useQuery("getInvoice", () => getInvoice(id), {
    refetchInterval: 1000,
    cacheTime: 0,
  });

  useEffect(() => {
    if (!!invoice && invoice.qrCode !== qr) {
      setQr(invoice.qrCode);
    }
  }, [invoice, setQr]);

  useEffect(() => {
    if (isSeenByCustomer(invoice)) {
      history.push(`/invoicestatus?id=${id}`);
    }
  }, [invoice]);

  if (!invoice) {
    return <Spinner text="Creating payment link" />;
  }

  return (
    <>
      <Header onBackClick={() => history.push("/invoiceadmin")} />
      <PageContainer className="InvoiceQRDesktop">
        <PageHeader text="Scan this QR with your phone's camera to open the payment link" />
        <FlexContainer margin="16px 0">
          <span className="InvoiceQRInfo">
            This QR-code is dynamically generated and can be distributed to your
            customer via SMS, e-mail, letter or in-store
          </span>
        </FlexContainer>
        <FlexContainer fullHeight justifyContent="center" alignItems="center">
          <QR qr={qr} />
        </FlexContainer>
      </PageContainer>

      <PageContainer className="InvoiceQRMobile">
        <PageHeader text="Your customer can access the invoice from this link." />
        <a href={qr} className="InvoiceQRMobileLink">
          Link
        </a>
      </PageContainer>
    </>
  );
};

interface Props {
  qr: string;
}
const WrappedComponent: React.FC<Props> = ({ qr }) => (
  <QRCode value={qr} height={180} width={180} />
);

// the second argument is a function that React.memo uses for comparison between renders.
// true means no rerender needed
const QR = React.memo(
  WrappedComponent,
  (prevProps, nextProps) => prevProps.qr === nextProps.qr
);

const isSeenByCustomer = (invoice: any) =>
  !!invoice && invoice.invoiceStatus !== InvoiceStatusEnum.Created;
