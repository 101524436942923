import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { FlexContainer } from "../../components/FlexContainer/FlexContainer";
import { PageContainer } from "../../components/PageContainer/PageContainer";
import useStore, { DemoStore } from "../../hooks/useStore";
import "./InvoiceAccounts.css";
import { ScaMethod } from "../../utils/enums";
import { useMutation, useQuery } from "react-query";
import { getAccounts, initiateInvoicePayment } from "../../api/endpoints";
import { Spinner } from "../../components/Loader/Loader";
import { isAndroid, isMobile } from "../../utils/functions";
import { Header } from "../../components/Header/Header";

export const InvoiceAccounts = () => {
  const [selectedAccount, setSelectedAccount] = useState(null);
  const history = useHistory();

  const {
    setConfirmPaymentBankIdLink,
    setInvoiceAccount,
    isChangingAccount,
    setQrImage,
    invoiceID,
    invoiceDate,
  } = useStore((state: DemoStore) => state);

  const {
    data: accounts,
    isFetching: isLoadingAccounts,
    refetch: fetchAccounts,
  } = useQuery("getInvoiceAccounts", () => getAccounts(), {
    enabled: false,
  });

  const {
    mutate: createPayment,
    isLoading: isCreatingPayment,
    data: createPaymentData,
    isSuccess: isCreatePaymentSuccess,
    reset: resetmutation,
  } = useMutation((body: any) => initiateInvoicePayment(body));

  useEffect(() => {
    fetchAccounts();
  }, []);

  useEffect(() => {
    if (accounts) {
      setSelectedAccount(accounts[0].id);
    }
  }, [accounts]);

  useEffect(() => {
    let invoiceAcc = null;

    if (accounts && selectedAccount !== null) {
      let account = accounts.find((acc) => acc.id === selectedAccount);
      if (account) {
        invoiceAcc = account;
      }
    }
    setInvoiceAccount(invoiceAcc);
  }, [selectedAccount, accounts]);

  const onActionClick = () => {
    if (isChangingAccount) {
      history.push("/invoicelanding");
    } else {
      createPayment({
        debtorAccount: accounts.find((acc) => acc.id === selectedAccount)
          .debtorAccount,
        isMobile: isMobile(),
        paymentDate: invoiceDate,
        invoiceID,
      });
    }
  };

  useEffect(() => {
    if (isCreatePaymentSuccess) {
      let { qrCode, scaOAuth, scaMethod, qrImage } = createPaymentData;

      setQrImage(qrImage);

      if (scaMethod === ScaMethod.Decoupled) {
        if (isAndroid()) {
          qrCode = qrCode.replace("bankid:///", "https://app.bankid.com/");
        }
        setConfirmPaymentBankIdLink(qrCode);
        resetmutation();
        if (isMobile()) {
          history.push("/paymentopenbankid");
        } else {
          history.push("/confirmpayment");
        }
      } else if (scaMethod === ScaMethod.OAuthRedirect) {
        window.location.href = scaOAuth;
      }
    }
  }, [isCreatePaymentSuccess]);

  if (!accounts || isLoadingAccounts) {
    return <Spinner text={"Loading accounts"} />;
  }

  if (isCreatingPayment) {
    return <Spinner text={"Initiating payment"} />;
  }
  return (
    <>
      <Header showCompanyName isInvoice />
      <PageContainer className="InvoicePageContainer">
        <FlexContainer flexDirection="column">
          <span className="ChooseAccountHeader">Välj konto</span>
          {accounts.map(({ id, name, balance, currency }) => (
            <Account
              key={id}
              name={name}
              isSelected={id === selectedAccount}
              balance={getBalanceText(balance, currency)}
              onClick={() => setSelectedAccount(id)}
            />
          ))}
        </FlexContainer>
        <button className="InvoiceAccountsAction" onClick={onActionClick}>
          {isChangingAccount ? "Välj konto" : "Betala"}
        </button>
      </PageContainer>
    </>
  );
};

const Account = ({ name, isSelected, balance, onClick }) => (
  <FlexContainer
    onClick={onClick}
    alignItems="center"
    className="InvoiceAccountsAccount"
  >
    <img
      src={`${isSelected ? "radiobutton_selected.svg" : "radiobutton.svg"}`}
      width="16"
      height="16"
      alt="radio"
      style={{ marginRight: "24px" }}
    />
    <FlexContainer flexDirection="column">
      <span className="InvoiceAccountName">{name}</span>
      <span className="InvoiceAccountBalance">{balance}</span>
    </FlexContainer>
  </FlexContainer>
);

const getBalanceText = (balance, currency) =>
  `${currency} ${formatAmount(balance)}`;

const formatAmount = (amount) => {
  if (isNaN(amount)) return amount;
  return (Math.round(amount * 100) / 100).toFixed(2);
};
