import { PageContainer } from "../../components/PageContainer/PageContainer";
import React from "react";
import { FlexContainer } from "../../components/FlexContainer/FlexContainer";
import { Header } from "../../components/Header/Header";
import { Product } from "../../utils/enums";
import useStore, { DemoStore } from "../../hooks/useStore";
import { isTestEnvironment } from "../../utils/functions";

export const PaymentSuccessful = () => {
  const product = useStore((state: DemoStore) => state.product);
  return (
    <>
      <Header />
      <PageContainer>
        <FlexContainer
          fullWidth
          fullHeight
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <img
            style={{
              height: isTestEnvironment() ? 300 : 80,
              width: "auto",
            }}
            alt="success"
            src={isTestEnvironment() ? "rb.jpeg" : `checkmark.svg`}
          />
          <h1>{getText(product)}</h1>
        </FlexContainer>
      </PageContainer>
    </>
  );
};

const getText = (product) => {
  switch (product) {
    case Product.PaymentInitiation:
      return "Payment confirmed";
    case Product.RecurringPayment:
      return "Recurring payment initiated";

    default:
      return "Success";
  }
};
