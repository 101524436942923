import { FlexContainer } from "../FlexContainer/FlexContainer";
import React from "react";
// import useStore from "useStore";
import { useHistory } from "react-router-dom";
import "./Header.css";
import useStore, { DemoStore } from "../../hooks/useStore";

const onBankClickDefault = () => null;

export const Header = ({
  onBackClick = onBankClickDefault,
  showBankInHeader = false,
  headline = "",
  hideBackButton = false,
  isInvoice = false,
  showCompanyName = false,
  showInvoiceText = false,
}) => {
  const bankID = useStore((state: DemoStore) => state.bankID);
  const bankName = useStore((state: DemoStore) => state.bankName);
  const history = useHistory();
  const goHome = () => history.push("/");

  const goToInvoiceLanding = () => history.push("/invoicelanding");

  if (isInvoice) {
    return (
      <FlexContainer
        justifyContent="space-between"
        className="HeaderInvoice"
        alignItems="center"
      >
        {showInvoiceText ? (
          <span className="HeaderFaktura">Faktura</span>
        ) : (
          <div></div>
        )}
        {showBankInHeader && <BankLogo bankID={bankID} bankName={bankName} />}
        {showCompanyName ? (
          <FlexContainer alignItems="center">
            <img
              src="company_icon.png"
              height="40"
              alt="logo"
              onClick={goToInvoiceLanding}
            />
            <span className="HeaderCompanyName" onClick={goToInvoiceLanding}>
              Företaget AB
            </span>
          </FlexContainer>
        ) : (
          <img
            src="company_icon.png"
            height="40"
            alt="logo"
            onClick={goToInvoiceLanding}
          />
        )}
      </FlexContainer>
    );
  }
  return (
    <FlexContainer
      justifyContent="space-between"
      className="Header"
      alignItems="center"
    >
      {hideBackButton ? (
        <div style={{ width: "50px" }}></div>
      ) : (
        <div
          style={{ width: "50px", display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={onBackClick === onBankClickDefault ? goHome : onBackClick}
        >
          <BackAction
            type={onBackClick === onBankClickDefault ? "CLOSE" : "BACK"}
          />
        </div>
      )}
      {!showBankInHeader && !!headline && (
        <div className="HeaderHeader">{headline}</div>
      )}
      {showBankInHeader && <BankLogo bankID={bankID} bankName={bankName} />}

      <img
        src="OP-icon-regular.svg"
        height="50px"
        alt="logo"
        style={{ cursor: "pointer" }}
        onClick={() => history.push("/")}
      />

    </FlexContainer>
  );
};

const BackAction = ({ type, action = () => null }) => {
  if (type === "BACK") {
    return <img src="backbutton.svg" onClick={action} alt="back" />;
  }
  if (type === "CLOSE") {
    return <img src="CloseIcon.svg" onClick={action} alt="close" />;
  }
};

const BankLogo = ({ bankID, bankName }) => (
  <FlexContainer alignItems="center">
    <img src={`${bankID}.png`} className="HeaderBankImage" alt="bank" />
    <span className="HeaderBankName">{bankName}</span>
  </FlexContainer>
);
