import { useEffect, useState, useRef } from "react";
import QRCode from "qrcode.react";
import {
  deleteConsent,
  getConsentAuthorisationSCAStatus,
} from "../../api/endpoints";
import { FlexContainer } from "../../components/FlexContainer/FlexContainer";
import { useQuery, useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { ErrorMessage } from "../../components/ErrorMessage/ErrorMessage";
import { PageHeader } from "../../components/PageHeader/PageHeader";
import { PageContainer } from "../../components/PageContainer/PageContainer";
import { Header } from "../../components/Header/Header";
import { Spinner } from "../../components/Loader/Loader";
import { BankId, Product, ScaStatus } from "../../utils/enums";
import { getEndpointTimestamp, isMobile } from "../../utils/functions";
import { parseErrorMessage } from "../../utils/reactQuery";
import useStore, { DemoStore } from "../../hooks/useStore";
import "./ConsentQr.css";
import InfoBox from "../../components/InfoBox";

export const ConsentQr = ({
  recreateConsent,
  resetConsentMutation,
  isConsentReset,
}) => {
  const history = useHistory();

  const {
    bankID,
    bankIdLink,
    product,
    addApiCall,
    setBankIdLink,
    qrImage,
  } = useStore((state: DemoStore) => state);

  const getConsentCountRef = useRef(0);
  const [showInfoLoader, setShowInfoLoader] = useState(false);
  const [hasClickedBack, setHasClickedBack] = useState(false);
  const [firstStatus, setFirstStatus] = useState(null);

  const [qrImageLocal, setQrImageLocal] = useState(qrImage);

  const { data, isError, error: getAuthStatusError, isFetching } = useQuery(
    "getConsentStatus",
    () => getConsentAuthorisationSCAStatus(),
    {
      refetchInterval: 1000,
      cacheTime: 0,
    }
  );

  useEffect(() => setQrImageLocal(qrImage), [qrImage, setQrImageLocal]);

  const {
    mutate: _deleteConsent,
    isLoading: isDeletingConsent,
    isSuccess: isDeleteConsentSuccess,
  } = useMutation(deleteConsent);

  useEffect(() => {
    if (!isFetching && !isError && getConsentCountRef.current > 1) {
      addApiCall({
        endpoint: "Get Consent Authorisation SCA Status",
        statusCode: "200",
        timestamp: getEndpointTimestamp(),
      });
    }
    if (isFetching) {
      getConsentCountRef.current++;
    }
  }, [isFetching, addApiCall, isError]);

  useEffect(() => {
    if (!!data && !!data.scaStatus && !firstStatus) {
      setFirstStatus(data.scaStatus);
    }

    if (hasQrImage(data)) {
      setQrImageLocal(data.qrImage);
    }

    if (hasFinalised(data)) {
      addApiCall({
        endpoint: "Get Consent Status",
        statusCode: "200",
        timestamp: getEndpointTimestamp(),
      });
      setBankIdLink(null);
      if (
        product === Product.PaymentInitiation ||
        product === Product.RecurringPayment
      ) {
        history.push("accounts");
      } else if (product === Product.AccountInformation) {
        history.push("/ais");
      } else if (product === Product.AccountVerification) {
        history.push("/accountverification");
      } else if (product === Product.Invoice) {
        history.push("/invoiceaccounts");
      }
    } else if (
      hasFailed(data) ||
      (isError && product === Product.AccountVerification)
    ) {
      if (getConsentCountRef.current > 10) {
        resetConsentMutation();
        if (product === Product.AccountVerification) {
          history.push("/accountverificationfailed");
        } else {
          recreateConsent(bankID);
        }
      }
    } else if (waitingForSecondScan(data)) {
      setShowInfoLoader(true);
    }
  }, [
    data,
    bankID,
    history,
    recreateConsent,
    isError,
    addApiCall,
    resetConsentMutation,
    setBankIdLink,
    product,
  ]);

  useEffect(() => {
    if (!!getAuthStatusError && product === Product.AccountVerification) {
      resetConsentMutation();
      history.push("/accountverificationfailed");
    }
  }, [getAuthStatusError, history, resetConsentMutation, product]);

  useEffect(() => {
    if (isDeleteConsentSuccess && isConsentReset) {
      history.push("/banks");
    }
  }, [isDeleteConsentSuccess, isConsentReset, history]);

  const _onBackClick = () => {
    setHasClickedBack(true);
    resetConsentMutation();
    _deleteConsent();
  };

  // if (!bankIdLink || isDeleteConsentError) {
  //   return <ErrorMessage />;
  // }

  if (!!getAuthStatusError && product !== Product.AccountVerification) {
    return <ErrorMessage text={parseErrorMessage(getAuthStatusError)} />;
  }

  if (showInfoLoader) {
    return (
      <Spinner text="Please keep your BankID app open, you will soon be asked to sign again" />
    );
  }

  if (isDeletingConsent || (hasClickedBack && !isConsentReset)) {
    return <Spinner text="Loading banks" />;
  }

  if (!firstStatus) {
    return <Spinner text="Generating QR code" />;
  }

  const isSwedbankDesktop = (bankId: string, isMobile: boolean) =>
    bankId === BankId.SWEDSESS && !isMobile;

  return (
    <>
      <Header
        onBackClick={_onBackClick}
        showBankInHeader={!isDeletingConsent}
        hideBackButton={isDeletingConsent}
      />
      <PageContainer>
        <FlexContainer flexDirection="column" fullHeight>
          <FlexContainer flexDirection="column" justifyContent="flex-start">
            <PageHeader text="Open the BankID app" />
            <FlexContainer margin="16px 0">
              <span>
                Open the Mobile BankID app and scan this QR code to
                authenticate.
              </span>
            </FlexContainer>
          </FlexContainer>
          {isSwedbankDesktop(bankID, isMobile()) && firstStatus !== "started" && (
            <FlexContainer flexDirection="column" alignItems="center">
              <InfoBox text="Please note that Swedbank requires two authentications." />
              <span className="AuthenticationNumber">{`Authentication ${
                !!data && data.scaStatus === "started" ? "2" : "1"
              } of 2`}</span>
            </FlexContainer>
          )}

          <FlexContainer
            fullWidth
            fullHeight
            justifyContent="center"
            alignItems="center"
          >
            {qrImageLocal ? (
              <img src={qrImageLocal} height="180px" width="180px" />
            ) : (
              <QRCode value={bankIdLink} height={180} width={180} />
            )}
          </FlexContainer>
        </FlexContainer>
      </PageContainer>
    </>
  );
};

const waitingForSecondScan = (data) =>
  !!data && data.scaStatus === ScaStatus.PsuAuthenticated;

const hasFinalised = (data) =>
  !!data &&
  (data.scaStatus === ScaStatus.Finalised ||
    data.scaStatus === ScaStatus.Exempted);

const hasFailed = (data) =>
  !!data &&
  (data.scaStatus === ScaStatus.Failed ||
    data.scaStatus === ScaStatus.Rejected);

const hasQrImage = (data) => !!data && !!data.qrImage;
