import { useEffect, useRef } from "react";
import { Spinner } from "../../components/Loader/Loader";
import { useQuery } from "react-query";
import { getConsentAuthorisationSCAStatus } from "../../api/endpoints";
import { useHistory } from "react-router-dom";
import { Product, ScaStatus } from "../../utils/enums";
import useStore, { DemoStore } from "../../hooks/useStore";
import { ErrorMessage } from "../../components/ErrorMessage/ErrorMessage";
import { parseErrorMessage } from "../../utils/reactQuery";

export const ConsentOpenBankID = ({ recreateConsent }) => {
  const history = useHistory();
  const {
    bankID,
    isFromChooseBank,
    setIsFromChooseBank,
    bankIdLink,
    product,
    setBankIdLink,
  } = useStore((state: DemoStore) => state);

  // Needed for correct loader text
  const getConsentCountRef = useRef(0);

  const { data, isFetching, error } = useQuery(
    "getConsentStatus",
    () => getConsentAuthorisationSCAStatus(),
    { refetchInterval: 2000, enabled: !isFromChooseBank }
  );

  useEffect(() => {
    if (isFetching) {
      getConsentCountRef.current++;
    }
  }, [isFetching]);

  useEffect(() => {
    if (!!bankIdLink && isFromChooseBank) {
      setIsFromChooseBank(false);
      // (window as any).location = bankIdLink;
      // window.location.href = bankIdLink;
      // setTest(bankIdLink);
    }
  }, [bankIdLink, isFromChooseBank, setIsFromChooseBank]);

  useEffect(() => {
    if (hasFinalised(data)) {
      setBankIdLink(null);
      if (
        product === Product.PaymentInitiation ||
        product === Product.RecurringPayment
      ) {
        history.push("/accounts");
      } else if (product === Product.AccountInformation) {
        history.push("/ais");
      } else if (product === Product.AccountVerification) {
        history.push("/accountverification");
      } else if (product === Product.Invoice) {
        history.push("/invoiceaccounts");
      }
    } else if (hasStarted(data)) {
      window.location.href =
        data.bankidLink || "bankid:///?autostarttoken=&redirect=";
    } else if (hasFailed(data)) {
      recreateConsent();
    }
  }, [data, bankID, history, recreateConsent, setBankIdLink, product]);

  if (!!error) {
    return <ErrorMessage text={parseErrorMessage(error)} />;
  }

  return (
    <Spinner
      text={getLoaderText(
        isFromChooseBank,
        product,
        getConsentCountRef.current
      )}
    />
  );
};

const getLoaderText = (
  isFromChooseBank: boolean,
  product: string,
  apiCallCount: number
) => {
  if (isFromChooseBank || apiCallCount === 0) {
    return "Open the BankID app";
  }
  switch (product) {
    case Product.PaymentInitiation:
    case Product.RecurringPayment:
    case Product.AccountVerification:
      return "Loading accounts";
    case Product.AccountInformation:
      return "Loading account data";
    default:
      return "Loading accounts";
  }
};

const hasStarted = (data: any) => {
  if (!data || !data.scaStatus) return false;
  return (
    data.scaStatus === ScaStatus.AuthenticationStarted ||
    data.scaStatus === ScaStatus.AuthoriseCreditorAccountStarted ||
    data.scaStatus === ScaStatus.Started
  );
};

const hasFinalised = (data: any) => {
  if (!data || !data.scaStatus) return false;
  return (
    data.scaStatus === ScaStatus.Finalised ||
    data.scaStatus === ScaStatus.Exempted
  );
};

const hasFailed = (data: any) => {
  if (!data || !data.scaStatus) return false;
  return (
    data.scaStatus === ScaStatus.Failed || data.scaStatus === ScaStatus.Rejected
  );
};
